import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { CREATE_ENTERPRISE_PROJECT } from '../../api/graphQL';
import { Spinner } from '../Components/Spinner';
import {
    selectAPIKey,
    setUserEmail,
    setEnterpriseWidgetProjectId,
    setWidgetState
} from '../../redux/reducers/enterprise_widget/enterpriseWidget';
import { setProjectArchitecture } from '../../redux/reducers/generic/projectArchitecture';
import { useNavigate } from 'react-router-dom';
import {
    fetchProjectSuccess,
    setMeetingRequested
} from '../../redux/reducers/generic/project';
import { setChatHistory } from '../../redux/reducers/generic/chatHistory';
import {
    selectEnterpriseOrganization,
    selectEnterpriseOrganizationError
} from '../../redux/reducers/enterprise/enterpriseOrganization';
import { addToProjects } from '../../redux/reducers/generic/projects';
import NewExistingProjectPicker from './NewExistingProjectPicker';
import UploadRFP from './UploadRFP';
import { clientWithAPIKey } from '../../api/client';
import { Loading } from '../Components/Loading';

const CreateNewProject = ({ id, isEnterprise, isWidget }) => {
    const apiKey = useSelector(selectAPIKey);
    const error = useSelector(selectEnterpriseOrganizationError);
    const [isLoading, setLoading] = useState(false);
    const [isUploading, setUploading] = useState(false);

    const [option, setOption] = useState('new');
    const [fileKey, setFileKey] = useState(null);
    const [email, setEmail] = useState('');
    const [newProjectName, setNewProjectName] = useState('');

    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);

    const dispatch = useDispatch();

    let [createProject] = useMutation(CREATE_ENTERPRISE_PROJECT, {
        client: clientWithAPIKey(apiKey)
    });
    const navigate = useNavigate();

    //UPON Creation of Project, call
    const handleNewProjectSubmit = async e => {
        e.preventDefault();
        dispatch(setUserEmail(email));

        if (!newProjectName) return alert('Please enter a project name');
        if (!email && isWidget) return alert('Please enter an email');

        //	e.preventDefault();
        if (newProjectName.trim() !== '') {
            setLoading(true);
            try {
                let new_project = await createProject({
                    variables: {
                        name: newProjectName,
                        hasExistingContext: option === 'existing',
                        type: 'other',
                        userEmail: email,
                        apiKey,
                        fileKeyS3: fileKey,
                        isWidget
                    }
                });

                if (isEnterprise) {
                    dispatch(
                        addToProjects(new_project.data.createEnterpriseProject)
                    );
                    navigate(
                        `/enterprise/dashboard/${enterpriseOrganization._id}/clientProjects/${new_project.data.createEnterpriseProject._id}`
                    );
                }

                dispatch(
                    setEnterpriseWidgetProjectId(
                        new_project.data.createEnterpriseProject._id
                    )
                );

                //Set up Project
                dispatch(
                    fetchProjectSuccess(
                        new_project.data.createEnterpriseProject
                    )
                );

                //Set up Chat History
                dispatch(
                    setChatHistory(
                        new_project.data.createEnterpriseProject.chatHistory
                    )
                );

                try {
                    dispatch(
                        setProjectArchitecture({
                            graphs: new_project.data.createEnterpriseProject
                                .graphs
                        })
                    );
                } catch (err) {
                    console.log({ err });
                }

                dispatch(
                    setMeetingRequested(
                        new_project.data.createEnterpriseProject
                            .meetingRequested
                    )
                );

                if (fileKey) {
                    dispatch(setWidgetState('projectCompleted'));
                } else dispatch(setWidgetState('chat'));

                setNewProjectName('');
                setLoading(false);
            } catch (err) {
                console.log({ err });
                alert(err.toString().replace('ApolloError: ', ''));
                setLoading(false);
            }
        }
    };

    return enterpriseOrganization ? (
        <form
            onSubmit={handleNewProjectSubmit}
            className="mb-4 flex flex-col items-center h-full justify-between sm:px-6 px-4"
        >
            <div>
                <p className="mb-4 mt-4 h-[20px] text-xl text-center tracking-tight font-extrabold leading-tight text-black">
                    {enterpriseOrganization?.projectCreationTitle}
                    {!enterpriseOrganization?.projectCreationTitle && (
                        <>
                            Start with an{' '}
                            <a
                                href="https://pre.dev"
                                className="font-bold text-blue-600"
                            >
                                idea
                            </a>
                        </>
                    )}
                </p>
                <NewExistingProjectPicker
                    option={option}
                    setOption={setOption}
                />
                {isWidget && (
                    <input
                        id={'email'}
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className=" border-gray-400 px-4 py-2 w-full outline-none mb-2"
                    />
                )}
                <input
                    id={id || 'createNewProject'}
                    type="text"
                    placeholder="Project Name"
                    value={newProjectName}
                    onChange={e => setNewProjectName(e.target.value)}
                    className="mt-4 border-gray-400 px-4 py-2 w-full outline-none mb-4"
                />
                <UploadRFP
                    setFileKey={setFileKey}
                    isUploading={isUploading}
                    setUploading={setUploading}
                />
            </div>
            <ContinueButton isLoading={isLoading} isUploading={isUploading} />
        </form>
    ) : error ? (
        <div className="h-full w-full w-[440px] flex justify-center items-center">
            <div className="text-center">
                <p className="mt-2 text-gray-500 mx-4">
                    There was an error loading the widget. Please contact the
                    site owner.
                </p>
            </div>
        </div>
    ) : (
        <div className="h-full w-full w-[440px] flex justify-center items-center">
            <Loading text="Widget" />
        </div>
    );
};

export const ContinueButton = ({ isLoading, isUploading }) => {
    return (
        <button
            type="submit"
            disabled={isLoading || isUploading}
            className={`mt-2 inline-flex items-center gap-x-2 rounded-md ${
                isLoading || isUploading ? 'bg-gray-400' : 'bg-blue-600'
            } px-6 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
        >
            {isLoading ? (
                <>
                    Creating Project
                    <Spinner w="5" />
                </>
            ) : (
                'Continue '
            )}
            {!isLoading && <>&rarr;</>}
        </button>
    );
};

export default CreateNewProject;
