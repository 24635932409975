import { GET_LEADERBOARD_ENTRY_FOR_PROJECT } from '../../../api/graphQL/solo/query';
import { client } from '../../../api/client';
import {
    fetchLeaderboardProjectEntryStart,
    setLeaderboardProjectEntry
} from '../../reducers/leaderboard';

export const getLeaderboardEntryForProject =
    (projectId: string) => async (dispatch: any) => {
        try {
            dispatch(fetchLeaderboardProjectEntryStart());

            const { data } = await client.query({
                query: GET_LEADERBOARD_ENTRY_FOR_PROJECT,
                variables: {
                    projectId
                },
                fetchPolicy: 'network-only'
            });

            console.log({ data });

            const entry = data.getLeaderboardEntryForProject;

            dispatch(setLeaderboardProjectEntry(entry));

            return 'Successfully fetched leaderboard entry for project.';
        } catch (error) {
            console.log({ error });
            return error.message;
        }
    };
