import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useProject } from '../../../context-providers/Project';
import { selectProjectArchitectureLoading } from '../../../redux/reducers/generic/projectArchitecture';
import { chatEndingIndex, getChatEndingIndex } from '../../../constants';
import { Icon } from '../Docs/TipTap/components/ui/Icon';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useSubscription } from '@apollo/client';
import { ARCHITECTURE_PROGRESS_SUBSCRIPTION } from '../../../api/graphQL/solo/subscription';

export const UpdatingArchitectureFloater = () => {
    const { project, chatHistory } = useProject();
    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState([]);

    let architectureLoading = useSelector(selectProjectArchitectureLoading);
    let currentIndex = getChatEndingIndex(chatHistory.data);
    let showPlaceholder = false;

    if (currentIndex < chatEndingIndex) showPlaceholder = true;
    if (project?.skip) showPlaceholder = false;

    useSubscription(ARCHITECTURE_PROGRESS_SUBSCRIPTION, {
        variables: { projectId: project._id },
        onData: ({ data }) => {
            if (data?.data?.architectureProgressStream?.items) {
                setProgress(data.data.architectureProgressStream.items);
            }
        },
        onError: error => {
            console.error(
                'Error in architecture progress subscription:',
                error
            );
        }
    });

    useEffect(() => {
        const updateZIndex = () => {
            const popperContent = document.querySelector(
                '[data-radix-popper-content-wrapper]'
            );
            if (popperContent) {
                popperContent.style.zIndex = '50';
            }
        };

        // Run once on mount
        updateZIndex();

        // Set up a MutationObserver to watch for changes in the DOM
        const observer = new MutationObserver(updateZIndex);
        observer.observe(document.body, { childList: true, subtree: true });

        // Clean up the observer on component unmount
        return () => observer.disconnect();
    }, []);

    let progressText =
        progress.find(item => !item.completed)?.description ||
        'Updating Architecture';

    return (
        architectureLoading &&
        !showPlaceholder && (
            <div className="mb-2">
                <DropdownMenu.Root open={open} onOpenChange={setOpen}>
                    <DropdownMenu.Trigger asChild>
                        <div className="flex h-full items-center px-4 gap-x-2 py-1 border border-gray-300 rounded-full my-2 cursor-pointer hover:bg-gray-800 transition-colors duration-200">
                            <div className="flex items-center rounded-full">
                                <Icon
                                    name="Loader"
                                    className="w-4 h-4 text-white animate-spin"
                                />
                            </div>
                            <h2 className="text-sm text-gray-300 text-center">
                                {progressText}
                            </h2>
                            {progress.length > 0 && (
                                <Icon
                                    name={open ? 'ChevronUp' : 'ChevronDown'}
                                    className="w-4 h-4 text-gray-300 ml-2"
                                />
                            )}
                        </div>
                    </DropdownMenu.Trigger>

                    {progress.length > 0 && (
                        <DropdownMenu.Portal className="z-50">
                            <DropdownMenu.Content
                                className="min-w-[220px] bg-black border border-2 border-gray-300 rounded-md p-2 shadow-md z-50"
                                sideOffset={5}
                            >
                                {progress.map((item, index) => (
                                    <DropdownMenu.Item
                                        key={index}
                                        className="flex items-center px-2 gap-x-2 py-1 text-sm text-gray-300 focus:outline-none"
                                    >
                                        {!item.completed ? (
                                            <Icon
                                                name="Loader"
                                                className="w-4 h-4 text-white animate-spin"
                                            />
                                        ) : (
                                            <Icon
                                                name="Check"
                                                className="w-4 h-4 text-green-500"
                                            />
                                        )}
                                        <span>{item.description}</span>
                                        <div className="ml-auto">
                                            {item.completed ? (
                                                <span className="text-green-500 text-xs">
                                                    100%
                                                </span>
                                            ) : (
                                                <span className="text-yellow-500 text-xs">{`${Math.round(
                                                    item.progress
                                                )}%`}</span>
                                            )}
                                        </div>
                                    </DropdownMenu.Item>
                                ))}
                            </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                    )}
                </DropdownMenu.Root>
            </div>
        )
    );
};
