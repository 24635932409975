import gql from 'graphql-tag';
import { enterprise, project } from '../helper';
export const GET_ENTERPRISE_ORGANIZATIONS_BY_USER = gql`
    query enterpriseOrganizationsByUser {
        enterpriseOrganizationsByUser {
            _id
            name
            created
        }
    }
`;

export const GET_CLIENT_JIRA_PROJECTS = gql`
    query getClientJiraProjects {
        getClientJiraProjects {
            id
            name
        }
    }
`;

export const GET_CLIENT_LINEAR_PROJECTS = gql`
    query getClientLinearProjects {
        getClientLinearProjects {
            id
            name
        }
    }
`;

export const GET_ENTERPRISE_ORGANIZATION_BY_USER = gql`
	query enterpriseOrganizationByUser($enterpriseOrganizationId: ID!) {
		enterpriseOrganizationByUser(
			enterpriseOrganizationId: $enterpriseOrganizationId
		) {
			${enterprise}
		}
	}
`;

export const GET_PROJECTS_BY_ENTERPRISE_ORGANIZATION = gql`
    query projectsByEnterpriseOrganization(
        $enterpriseOrganizationId: ID!
        $skip: Int!
        $limit: Int!
        $searchQuery: String
        $creationMethodFilter: String
        $sort: Sort
    ) {
        projectsByEnterpriseOrganization(
            enterpriseOrganizationId: $enterpriseOrganizationId
            skip: $skip
            limit: $limit
            searchQuery: $searchQuery
            creationMethodFilter: $creationMethodFilter
            sort: $sort
        ) {
            projects {
                _id
                name
                type
                created
                updated
                meetingRequested
                userEmail
                creationMethod
                messageCount
            }
            totalProjects
        }
    }
`;

export const GET_PROJECT_BY_ENTERPRISE_ORGANIZATION = gql`
	query projectByEnterpriseOrganization(
		$projectId: ID!
		$enterpriseOrganizationId: ID!
	) {
		projectByEnterpriseOrganization(
			projectId: $projectId
			enterpriseOrganizationId: $enterpriseOrganizationId
		) {
			${project}
		}
	}
`;

export const ENTERPRISE_STRIPE_LINK = gql`
    query enterpriseStripeLink($enterpriseOrganizationId: ID!) {
        enterpriseStripeLink(
            enterpriseOrganizationId: $enterpriseOrganizationId
        )
    }
`;
