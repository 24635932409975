import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { toast } from 'sonner';
import { getWebsiteDomain } from '../../../../auth_config';
import { AddDomain } from '../Domains/addDomain';
import { DomainList } from '../Domains/domainList';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export const WidgetIntegrationGuide = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const { isSubbed } = useSubscriptionPayment();

    const apiKey = enterpriseOrganization?.apiKey;

    const copyToClipboard = text => e => {
        navigator.clipboard.writeText(text);
        toast('Copied to clipboard');
    };

    const handleViewLink = () => {
        if (!isSubbed) {
            toast.error(
                'Please upgrade to Enterprise Premium to view this link.'
            );
        }
    };

    let iframe_str = `
	<iframe height="590px" width="420px" src="${getWebsiteDomain()}/iframe/enterprise/chat/${apiKey}"/>`;

    let clientOnboardingLink = apiKey
        ? `${getWebsiteDomain()}/enterprise/chat/${apiKey}?`
        : '';

    return (
        <>
            <div className="flex flex-col">
                <p className="text-sm text-gray-500">
                    Follow the steps below to integrate the pre.dev widget into
                    your website or use{' '}
                    <a
                        href={clientOnboardingLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                    >
                        this link
                    </a>{' '}
                    to onboard your clients.
                </p>
                <p className="text-sm text-gray-500">
                    {' '}
                    Visit{' '}
                    <a
                        href="https://demo-agency.pre.dev"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                    >
                        demo-agency.pre.dev
                    </a>{' '}
                    to see an example of the widget.
                </p>
            </div>

            <div className="mt-6">
                <ol className="list-decimal gap-y-6 divide-y">
                    <li className="mb-4 text-sm leading-5 text-gray-500 ml-4">
                        <p className="text-black">Embed HTML Code</p>
                        <p className="mt-4 flex justify-between">
                            {isSubbed ? (
                                <code
                                    className="cursor-pointer text-blue-500 underline break-all"
                                    onClick={copyToClipboard(iframe_str)}
                                >
                                    {iframe_str}
                                </code>
                            ) : (
                                <button
                                    onClick={handleViewLink}
                                    className="text-blue-600 hover:underline"
                                >
                                    View Embed HTML Code
                                </button>
                            )}
                        </p>
                    </li>
                    <li className="mt-4 text-sm leading-5 text-gray-500 ml-4">
                        <p className="text-black mt-4">Add Respective Domain</p>
                        <p className="mt-4 flex justify-between">
                            Wherever you are using the widget, make sure to add
                            the respective domain to your organization on the
                            onboarding page.
                        </p>
                        <AddDomain />
                        <DomainList />
                    </li>
                </ol>
            </div>
        </>
    );
};
