import { REPORT_LEADERBOARD_ENTRY } from '../../../api/graphQL/solo/mutator';
import { client } from '../../../api/client';

export const reportLeaderboardEntry =
    (leaderboardEntryId: string) => async (dispatch: any) => {
        try {
            const { data } = await client.mutate({
                mutation: REPORT_LEADERBOARD_ENTRY,
                variables: {
                    leaderboardEntryId
                },
                fetchPolicy: 'network-only'
            });

            return 'Successfully reported leaderboard entry.';
        } catch (error) {
            console.error({ error });
            return error.message;
        }
    };
