import { useState } from 'react';
import { FaLayerGroup } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import Modal from '../../../Components/Modal';
import { TechStackTable } from '../../TechStack';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { useDispatch } from 'react-redux';
import { toggleSubscribeModal } from '../../../../redux/reducers/generic/billing';
import { toast } from 'sonner';
import { useProject } from '../../../../context-providers/Project';

export const TechStackButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isShare, isSubbed, isHomePage } = useSubscriptionPayment();
    const { project } = useProject();
    const dispatch = useDispatch();

    const handleClick = () => {
        if (isShare) {
            toast.info('You need to login to view the tech stack');
        } else if (isSubbed || (project?.isSubscribed && !isHomePage)) {
            setIsModalOpen(true);
        } else {
            dispatch(toggleSubscribeModal(true));
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <button
                onClick={handleClick}
                data-tooltip-id={'tech-stack-tooltip'}
                data-tooltip-content="View Tech Stack"
                className="p-2 text-blue-600 border border-blue-700 rounded-full hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-colors duration-200"
            >
                <FaLayerGroup className="h-4 w-4" />
            </button>
            <Tooltip id={'tech-stack-tooltip'} place="top" />
            <Modal
                open={isModalOpen}
                title={'Tech Stack'}
                onClose={handleCloseModal}
                maxWidth="max-w-xl"
                bgColor="bg-white"
            >
                <TechStackTable />
            </Modal>
        </>
    );
};
