import { useDispatch, useSelector } from 'react-redux';
import { useProject } from '../../../../context-providers/Project';
import { getSow } from '../../../../redux/actions/solo/getSow';
import { selectSowVersionLoading } from '../../../../redux/reducers/generic/project';
import { selectSow, setEditMode } from '../../../../redux/reducers/generic/sow';
import { generateSowCustom } from '../../../../redux/actions/solo/generateSoWCustom';

export const CustomDocsToggle = ({ isEnterprise }) => {
    const { project } = useProject();
    const sow = useSelector(selectSow);
    const dispatch = useDispatch();
    const type = sow?.type;

    const handleToggleDoc = async () => {
        if (sowVersionLoading[type]) return;
        let sow = await dispatch(
            getSow(project._id.toString(), type === 'mvp' ? 'custom' : 'mvp')
        );
        if (type === 'custom') {
            dispatch(setEditMode(false));
        }
        if (type === 'mvp' && !sow) {
            console.log('Generating custom sow');
            let success = await dispatch(
                generateSowCustom(project._id.toString(), 'custom')
            );
            console.log({ success });
            if (!success) {
                await dispatch(getSow(project._id.toString(), 'mvp'));
            }
        }
    };

    const sowVersionLoading = useSelector(selectSowVersionLoading);

    let text = type === 'mvp' ? ' Custom Docs' : ' Original Docs';
    if (sowVersionLoading[type]) {
        text =
            'Loading ' +
            (type === 'mvp' ? 'Original Docs...' : 'Custom Docs...');
    } else text = 'View' + text;

    if (!isEnterprise || !sow?.projectId) return null;

    return (
        <button
            disabled={sowVersionLoading[type]}
            onClick={() => handleToggleDoc()}
            className="flex h-[30px] items-center px-2 text-xs font-medium text-gray-900 focus:outline-none rounded-lg text-black border border-black hover:bg-gray-100 focus:z-10 focus:ring-gray-200"
        >
            {text}
        </button>
    );
};
