import { DELETE_COMMENT } from '../../../api/graphQL/solo/mutator';
import { client } from '../../../api/client';
import {
    setLeaderboardEntry,
    setLeaderboardProjectEntry
} from '../../reducers/leaderboard';

export const deleteComment = (commentId: string) => async (dispatch: any) => {
    try {
        const { data } = await client.mutate({
            mutation: DELETE_COMMENT,
            variables: {
                commentId
            },
            fetchPolicy: 'network-only'
        });

        dispatch(setLeaderboardEntry(data.deleteComment));
        dispatch(setLeaderboardProjectEntry(data.deleteComment));

        return 'Successfully deleted comment.';
    } catch (error) {
        console.error({ error });
        return error.message;
    }
};
