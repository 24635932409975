import React, { useState } from 'react';
import { Spinner } from '../../Components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { removeTechStackItem } from '../../../redux/actions/solo/removeTechStackItem';
import {
    selectRecommendedTechStackLoading,
    selectTechStackVersion
} from '../../../redux/reducers/generic/project';
import { useProject } from '../../../context-providers/Project';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';

export function TechStackItem({ idx, tech, isShare }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { project } = useProject();
    const { projectId } = useParams();
    const recommendedStackLoading = useSelector(
        selectRecommendedTechStackLoading
    );
    const techStackVersion = useSelector(selectTechStackVersion);
    const { isSubbed } = useSubscriptionPayment();

    const isLatestVersion = techStackVersion === project?.techStackVersions[0];

    const onDelete = async () => {
        setLoading(true);
        await dispatch(
            removeTechStackItem({
                projectId,
                index: idx
            })
        );
        setLoading(false);
    };

    return (
        <React.Fragment key={idx}>
            <tr className={`${idx % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}>
                <th className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap">
                    <span>Name</span>
                </th>
                <td className="px-6 py-1 flex items-center justify-between h-full">
                    <a
                        href={tech.link}
                        className="text-blue-500 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {tech.name}
                        {tech.useFor && (
                            <span className="text-gray-500 text-sm">
                                {' '}
                                ({tech.useFor})
                            </span>
                        )}
                    </a>

                    {loading && <Spinner w="4" />}
                    {!loading && isSubbed && isLatestVersion && (
                        <button
                            type="button"
                            className="text-red-500 hover:text-red-700 text-center"
                            onClick={onDelete}
                        >
                            Delete
                        </button>
                    )}
                </td>
            </tr>

            {tech.description && (
                <tr
                    className={`${
                        idx % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'
                    }`}
                >
                    <th
                        scope="row"
                        className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap"
                    >
                        Description
                    </th>
                    <td className="px-6 py-1">{tech.description}</td>
                </tr>
            )}
            {tech.reason && (
                <tr
                    className={`${
                        idx % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'
                    }`}
                >
                    <th
                        scope="row"
                        className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap"
                    >
                        Reason
                    </th>
                    <td className="px-6 py-1">{tech.reason}</td>
                </tr>
            )}

            {tech.promo && (
                <tr
                    className={`${
                        idx % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'
                    }`}
                >
                    <th
                        scope="row"
                        className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap"
                    >
                        PROMO CODE
                    </th>
                    <td className="px-6 py-1">{tech.promo}</td>
                </tr>
            )}

            {tech.helpfulLinks && (
                <tr
                    className={`${
                        idx % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'
                    }`}
                >
                    <th
                        scope="row"
                        className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap"
                    >
                        Helpful Links
                    </th>
                    <td className="px-6 py-1">
                        {tech.helpfulLinks.map(
                            (link, linkIdx) =>
                                link.url &&
                                link.description && (
                                    <div key={linkIdx} className="py-1">
                                        <a
                                            href={link.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline truncate block max-w-xs"
                                            title={link.description}
                                        >
                                            {link.description}
                                        </a>
                                    </div>
                                )
                        )}
                    </td>
                </tr>
            )}

            {tech.alternatives && (
                <tr
                    className={`${
                        idx % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'
                    }`}
                >
                    <th
                        scope="row"
                        className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap"
                    >
                        Alternatives
                    </th>
                    <td className="px-6 py-1">
                        {tech.alternatives.map((alt, altIdx) => (
                            <div key={altIdx} className="py-1">
                                <a
                                    href={alt.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 hover:underline"
                                >
                                    {alt.name}
                                </a>
                                <span>: {alt.description}</span>
                            </div>
                        ))}
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
}
