import { useSelector } from 'react-redux';
import {
    selectLoadingSowCustom,
    selectLoadingSowMVP,
    selectSow
} from '../../../../redux/reducers/generic/sow';
import { Icon } from '../TipTap/components/ui/Icon';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const Loading = () => {
    const mvpLoading = useSelector(selectLoadingSowMVP);
    const customLoading = useSelector(selectLoadingSowCustom);
    const sow = useSelector(selectSow);
    const type = sow?.type || 'mvp';
    const isLoadingSow = type === 'mvp' ? mvpLoading : customLoading;

    if (isLoadingSow || !sow?.completed) {
        return (
            <>
                <div
                    className="flex items-center space-x-3 px-2 py-2 border-2 border-indigo-500 rounded-full bg-white"
                    data-tooltip-id="loading-tooltip"
                    data-tooltip-content="Loading Documentation..."
                >
                    <Icon
                        name="Loader"
                        className="w-4 h-4 text-indigo-500 animate-spin"
                    />
                </div>
                <Tooltip id="loading-tooltip" place="bottom" />
            </>
        );
    }
};
