import { useSubscription } from '@apollo/client';
import { UIVIEW_SUBSCRIPTION } from '../../../../api/graphQL';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cn } from '../../../Components/magicui/lib/utils';
import { buttonVariants } from '../../../Components/magicui/ui/button';
import { ChevronLeft } from 'lucide-react';
import { Tabs } from './Tabs';
import {
    selectShowNodeDetailModal,
    setShowNodeDetailModal,
    selectSelectedNode,
    setUIView,
    selectUIView,
    selectUIViewLoading,
    selectUIViewEditing,
    selectGettingUIView
} from '../../../../redux/reducers/generic/projectArchitecture';
import { getUIView } from '../../../../redux/actions/solo/getUIView';
import { getUIViews } from '../../../../redux/actions/solo/getUIViews';
import { saveUIView } from '../../../../redux/actions/solo/saveUIView';
import { toast } from 'sonner';

import { useProject } from '../../../../context-providers/Project';
import { useUIState } from './hooks/useUIState';
import { useScreenshot } from './hooks/useScreenshot';
import { useFileManagement } from './hooks/useFileManagement';

import { UiCta } from './UiCta';
import { UIBuilder } from './UIBuilder';

const excludedFiles = ['/Container.tsx', '/index.tsx', '/styles.css'];

const tabs = [
    { name: 'Code', href: '#', current: true },
    { name: 'Preview', href: '#', current: false }
];

export const NodeDetailModal = () => {
    const [currentTab, setCurrentTab] = useState(
        tabs.find(tab => tab.current).name
    );
    const [showUIBuilder, setShowUIBuilder] = useState(true);
    const timeoutRef = useRef(null);

    const dispatch = useDispatch();
    const { projectId, project, projectArchitecture } = useProject();
    const theme = project?.theme;

    const open = useSelector(selectShowNodeDetailModal);
    const node = useSelector(selectSelectedNode);
    const nodeId = node?._id;
    const gettingUIView = useSelector(selectGettingUIView);
    const loadingUIView = useSelector(selectUIViewLoading);
    const editingPhase = useSelector(selectUIViewEditing);
    const uiView = useSelector(selectUIView);

    const {
        activeTab,
        setActiveTab,
        screenshot,
        setScreenshot,
        uploading,
        setUploading,
        imageUrl,
        setImageUrl,
        fileKeyScreenshot,
        setFileKeyScreenshot
    } = useUIState();

    const {
        files,
        testFiles,
        filesWithTests,
        regenerateUI,
        onTestComplete,
        testingPhase,
        setFiles,
        setTestingPhase,
        setTestFiles,
        setFilesWithTests
    } = useFileManagement({
        dispatch,
        projectId,
        nodeId,
        node,
        uiView,
        setUIView,
        setImageUrl,
        setFileKeyScreenshot,
        projectArchitecture
    });

    const [isEditing, setIsEditing] = useState(false);
    const { handleScreenshotEvent } = useScreenshot({
        setImageUrl,
        setUploading,
        setFileKeyScreenshot,
        dispatch
    });

    useSubscription(UIVIEW_SUBSCRIPTION, {
        variables: { projectId, nodeId },
        shouldResubscribe: true,
        onData: data => {
            try {
                setFilesWithTests(null);
                setTestingPhase(false);
                dispatch(setUIView(data.data.data.uiviewStream));
            } catch (error) {
                console.error(error);
            }
        },
        onError: error => {
            console.error(JSON.stringify(error, null, 2));
        }
    });

    const [extraOptions, setExtraOptions] = useState(false);

    useEffect(() => {
        if (node) {
            if (node.id !== 'core') {
                dispatch(
                    getUIView({
                        nodeId: node._id,
                        projectId: projectId,
                        forceNew: false
                    })
                );
            } else {
                console.log('Core node so fetching all views');
                setTestingPhase(false);
                dispatch(
                    getUIViews({ nodeId: node._id, projectId: projectId })
                );
            }
        }
    }, [node]);

    useEffect(() => {
        function receiveMessage(event) {
            if (event.data.type === 'screenshot') {
                setScreenshot(event.data.imgData);
                handleScreenshotEvent(event.data.imgData);
            }
        }

        window.addEventListener('message', receiveMessage, false);

        return () => {
            window.removeEventListener('message', receiveMessage, false);
        };
    }, [handleScreenshotEvent]);

    useEffect(() => {
        console.log('activeTab changed:', currentTab);
        console.log('Setting showUIBuilder to false');
        setShowUIBuilder(false);
        console.log('Setting timeout');
        setTimeout(() => {
            console.log('Timeout completed, setting showUIBuilder to true');
            setShowUIBuilder(true);
        }, 100);
    }, [currentTab]);

    const [editLoading, setEditLoading] = useState(false);

    const handleSave = async () => {
        try {
            let updatedExcluded = excludedFiles;
            const isGlobal = Object.values(files).some(f => f.uiViewId);

            if (isGlobal) {
                updatedExcluded.push('/App.tsx');
            }

            const allFiles = Object.entries(files)
                .filter(([path, code]) => !updatedExcluded.includes(path))
                .map(([path, code]) => ({
                    filepath: isGlobal
                        ? path.replace(/^\/components\/[^\/]+\//, '/')
                        : path,
                    code: code.code,
                    uiViewId: code.uiViewId
                }));
            const message = await dispatch(
                saveUIView({
                    projectId,
                    nodeId,
                    version: 0,
                    codeFiles: allFiles
                })
            );
            toast(message);
        } catch (error) {
            console.log(error);
            toast('Error saving code');
        }
    };

    const handleBlack = () => {
        dispatch(setShowNodeDetailModal(null));
        dispatch(setUIView(null));
        setFiles(null);
        setTestFiles(null);
        setFilesWithTests(null);
        setTestingPhase(true);
    };

    return (
        <div className="mt-8 overflow-y-scroll">
            <div className="flex justify-between items-center mb-4">
                <div
                    onClick={handleBlack}
                    className={cn(
                        buttonVariants({ variant: 'ghost' }),
                        'text-white cursor-pointer'
                    )}
                >
                    <ChevronLeft className="mr-2 h-4 w-4" />
                    Back
                </div>
                {uiView && uiView.status == 'finished' && !testingPhase && (
                    <div className="absolute left-1/2 -ml-2 w-[300px]">
                        <Tabs
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                        />
                    </div>
                )}
                <div className="invisible">
                    <ChevronLeft className="mr-2 h-4 w-4" />
                    Back
                </div>
            </div>
            <div>
                <div className="flex flex-col sm:grid sm:grid-cols-3 mb-2">
                    <div className="hidden sm:flex flex-col gap-y-2 text-center">
                        {/* Placeholder for future content */}
                    </div>

                    <div className="flex justify-end mr-8 ">
                        {/* Placeholder for future content */}
                    </div>
                </div>
                {!uiView ? (
                    <UiCta
                        node={node}
                        projectId={projectId}
                        loading={gettingUIView}
                    />
                ) : (
                    <>
                        {showUIBuilder &&
                            UIBuilder({
                                activeTab: currentTab,
                                setActiveTab: setCurrentTab,
                                node,
                                regenerateUI,
                                uiView,
                                testingPhase,
                                editingPhase,
                                loadingUIView,
                                gettingUIView,
                                filesWithTests,
                                files,
                                setFiles,
                                imageUrl,
                                onTestComplete,
                                handleSave,
                                theme,
                                screenshot,
                                extraOptions,
                                setExtraOptions,
                                isEditing,
                                setIsEditing
                            })}
                    </>
                )}
            </div>
        </div>
    );
};
