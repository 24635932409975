import { Link } from 'react-router-dom';
import { salesCalendlyLink } from '../../constants';
import {
    ArrowPathIcon,
    ClockIcon,
    CodeBracketIcon,
    CurrencyDollarIcon,
    UserGroupIcon,
    ChartBarIcon
} from '@heroicons/react/24/outline';
import Widget from '../WidgetPages/ChatWidgetPage/Widget';
import { WhitelabelWidget } from '../Landing/Whitelabel';

export function DeveloperAgencies() {
    const comparisonData = [
        {
            title: 'Before pre.dev',
            steps: [
                {
                    text: 'Client fills in a standard contact form with minimal details',
                    time: '1 minute'
                },
                {
                    text: (
                        <>
                            <strong>Multiple calls</strong> to discuss the idea
                            and understand the scope
                        </>
                    ),
                    time: '3-4 weeks'
                },
                {
                    text: (
                        <>
                            PM and engineers <strong>scope out</strong> the
                            project
                        </>
                    ),
                    time: '4-8 hours'
                },
                {
                    text: 'Call with client to discuss the proposal',
                    time: '1 hour'
                },
                { text: 'Client approves the proposal', time: '1-3 days' },
                {
                    text: (
                        <>
                            PM <strong>manually assigns</strong> tasks to
                            engineers
                        </>
                    ),
                    time: '~1 week'
                },
                {
                    text: 'Engineers start work'
                }
            ],
            icon: <ClockIcon className="h-12 w-12 text-red-400" />,
            totalTime: '4-6 weeks'
        },
        {
            title: 'After pre.dev',
            steps: [
                {
                    text: (
                        <>
                            Client fills in the <strong>pre.dev widget</strong>
                        </>
                    ),
                    time: '3 minutes'
                },
                {
                    text: (
                        <>
                            Agency reviews{' '}
                            <strong>
                                generated architecture, statement of work, and
                                roadmap
                            </strong>
                        </>
                    ),
                    time: '1-2 hours'
                },
                {
                    text: <>First call with client to review the proposal</>,
                    time: '1 hour'
                },
                { text: 'Client approves the proposal', time: '1-3 days' },
                {
                    text: (
                        <>
                            Engineers start work immediately with{' '}
                            <strong>auto-assigned tasks</strong>
                        </>
                    )
                }
            ],
            icon: <ArrowPathIcon className="h-12 w-12 text-green-400" />,
            totalTime: '2-4 days'
        }
    ];

    const advantages = [
        {
            text: 'Streamlined client onboarding process',
            icon: UserGroupIcon
        },
        {
            text: 'Automated project scoping and task assignment',
            icon: CodeBracketIcon
        },
        {
            text: 'Increased efficiency and reduced time-to-start',
            icon: ClockIcon
        },
        {
            text: 'Improved project cost estimation',
            icon: CurrencyDollarIcon
        },
        {
            text: 'Enhanced client communication and transparency',
            icon: ChartBarIcon
        }
    ];

    const timeSaved = '3-5 weeks per client';

    return (
        <div className="bg-gray-900 min-h-screen text-white mt-[56px]">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <h1 className="text-4xl font-bold mb-8">Developer Agencies</h1>
                <h2 className="text-2xl font-bold mb-6">
                    Client Onboarding Comparison
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                    {comparisonData.map((data, index) => (
                        <div
                            key={index}
                            className="bg-gray-800 rounded-lg p-6 flex flex-col h-full justify-between"
                        >
                            <div className="flex flex-col">
                                <div className="flex items-center mb-4">
                                    {data.icon}
                                    <h3 className="text-xl font-bold ml-4">
                                        {data.title}
                                    </h3>
                                </div>
                                <ol className="list-decimal list-inside flex-grow">
                                    {data.steps.map((step, stepIndex) => (
                                        <li
                                            key={stepIndex}
                                            className="mb-2 py-1"
                                        >
                                            {step.text}{' '}
                                            {step.time && (
                                                <span className="text-indigo-400">
                                                    ({step.time})
                                                </span>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            </div>
                            <div className="pt-4">
                                <p className="font-semibold text-lg bg-gray-700 rounded-lg p-3 shadow-lg border-2 border-indigo-500">
                                    Total time:{' '}
                                    <span className="font-extrabold text-xl">
                                        {data.totalTime}
                                    </span>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="bg-indigo-700 rounded-lg p-6 mb-8 text-center">
                    <h3 className="text-2xl font-bold mb-2">
                        Time Saved with pre.dev
                    </h3>
                    <p className="text-3xl font-extrabold mb-4">{timeSaved}</p>
                    <div className="flex justify-center space-x-4">
                        <a
                            href={salesCalendlyLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-block bg-white text-indigo-700 font-bold py-2 px-6 rounded-lg hover:bg-indigo-100 transition-colors"
                        >
                            Talk to Sales
                        </a>
                        <Link
                            to="/enterprise/dashboard"
                            className="inline-block bg-green-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-green-600 transition-colors"
                        >
                            Get Started
                        </Link>
                    </div>
                </div>
                <div className="flex justify-center mx-auto w-fit">
                    <WhitelabelWidget />
                </div>

                <h2 className="text-2xl font-bold my-4">
                    Key Advantages for Developer Agencies
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                    {advantages.map((advantage, index) => (
                        <div
                            key={index}
                            className="bg-gray-800 rounded-lg p-4 flex items-center"
                        >
                            <advantage.icon className="h-8 w-8 text-indigo-400 mr-4" />
                            <span className="text-lg">{advantage.text}</span>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center space-x-4">
                    <a
                        href={salesCalendlyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-gray-600 hover:bg-gray-500 text-white font-bold py-3 px-6 rounded-lg text-lg"
                    >
                        Talks to Sales
                    </a>
                </div>
            </div>
        </div>
    );
}
