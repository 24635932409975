import React from 'react';
import { ImageCarousel } from './image_carousel';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export function Story({ story }) {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    return (
        <div className="overflow-hidden" ref={ref}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl items-center grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <StoryHeader
                                icon={story.icon}
                                title={story.step_title}
                                isEnterpriseExclusive={
                                    story.enterprise_exclusive
                                }
                            />
                            <StoryDescription description={story.description} />
                            <StoryFeatures features={story.features} />
                            {/* <UserStory userStory={story.user_story} /> */}
                        </div>
                    </div>
                    <div>
                        <ImageCarousel screenshots={story.screenshots} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const StoryHeader = ({ icon, title, isEnterpriseExclusive }) => (
    <div className="relative">
        <h2 className="text-base text-left font-semibold leading-7 text-indigo-400 w-fit">
            {icon}
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {title}
        </p>
        {isEnterpriseExclusive && <EnterpriseExclusiveTag />}
    </div>
);

const EnterpriseExclusiveTag = () => (
    <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="absolute top-0 right-0 bg-gradient-to-r from-purple-600 to-indigo-600 text-white text-xs font-bold py-2 px-4 rounded-full shadow-lg flex gap-x-1"
    >
        <img src="/predev.png" alt="pre.dev logo" className="h-4 mr-1" />
        Enterprise Exclusive
    </motion.div>
);

const StoryDescription = ({ description }) => (
    <p className="mt-6 text-lg leading-8 text-gray-300">{description}</p>
);

const StoryFeatures = ({ features }) => (
    <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
        {features.map(feature => (
            <div key={feature.feature_title} className="relative pl-9">
                <dt className="inline font-semibold text-white">
                    {feature.icon &&
                        React.cloneElement(feature.icon, {
                            className:
                                'absolute left-1 top-1 h-5 w-5 text-grey-400'
                        })}
                    {feature.feature_title}
                </dt>{' '}
                <dd className="inline">{feature.description}</dd>
            </div>
        ))}
    </dl>
);

const UserStory = ({ userStory }) => (
    <p className="mt-6 text-lg leading-8 text-gray-300">
        <strong className="text-white">User Story:</strong> {userStory}
    </p>
);
