import { useState } from 'react';
import { submitHeroExperience } from '../../../redux/actions/solo/submitHeroExperience';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { getProjectByLink } from '../../../redux/actions/solo/getProjectByLink';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { RandomButtons } from './random_buttons';
import { useEffect } from 'react';

export const InputArea = ({
    answers,
    setAnswers,
    setShowInput,
    setCountdown,
    setLoading,
    setQuestionIndex,
    questionIndex
}) => {
    const navigate = useNavigate();
    const [answer, setAnswer] = useState('');
    const { accessTokenPayload } = useSessionContext();
    const userEmail = accessTokenPayload?.email;

    const dispatch = useDispatch();

    //Auto set to email if on last question
    useEffect(() => {
        if (userEmail && questionIndex == 2) setAnswer(userEmail);
    }, [questionIndex]);

    const processAnswer = async () => {
        if (answer.length < 1) {
            toast('Please enter an answer.');
            return;
        }
        setQuestionIndex(questionIndex + 1);

        if (questionIndex == 2) {
            const email = answer.trim();
            // Email validation regex pattern
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setAnswer('');
            if (!emailPattern.test(email)) {
                toast('Please enter a valid email address.');
                return;
            }
            setShowInput(false);
            setLoading(true);
            let countdownTime = 5;
            setCountdown(countdownTime);

            let project_id;

            if (window.innerWidth <= 768) {
                document.activeElement.blur(); // This will lower the keyboard on mobile
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }, 300); // Delay to ensure the scroll happens before lowering the keyboard
            }

            try {
                let countInterval = setInterval(() => {
                    if (countdownTime > 0) {
                        countdownTime--;
                        setCountdown(countdownTime);
                    } else {
                        if (project_id) {
                            navigate('/demo/' + project_id);
                            clearInterval(countInterval);
                        }
                    }
                }, 1000);

                try {
                    project_id = await dispatch(
                        submitHeroExperience({
                            answers: answers,
                            userEmail: email
                        })
                    );
                    dispatch(getProjectByLink(project_id));
                } catch (e) {
                    navigate('/auth');
                }
            } catch (e) {
                navigate('/auth');
            }
        } else {
            setShowInput(false);
            setAnswers(prevAnswers => [...prevAnswers, answer]);
        }
    };

    const placeholderText =
        questionIndex == 0
            ? 'Generate a development plan in seconds...'
            : questionIndex == 1
              ? 'Ex: they see a customer analytics dashboard...'
              : 'Email address';

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                processAnswer();
            }}
        >
            <textarea
                type={questionIndex == 1 ? 'email' : 'text'}
                rows="4"
                required
                autoFocus
                value={answer}
                onChange={e => setAnswer(e.target.value)}
                className={`text-green-300 w-[calc(100%-2rem)] min-h-[200px] md:pl-8 md:mx-4 border-transparent focus:border-transparent focus:ring-0 font-light terminal-input text-left ${
                    answer.length < 50
                        ? 'md:text-5xl text-3xl'
                        : answer.length < 100
                          ? 'md:text-4xl text-2xl'
                          : answer.length < 150
                            ? 'md:text-3xl text-xl'
                            : 'md:text-2xl text-lg'
                }`}
                placeholder={placeholderText}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        processAnswer();
                    }
                }}
            />
            <div className="flex flex-row items-center justify-between mt-4">
                <RandomButtons
                    answer={answer}
                    answers={answers}
                    setAnswer={setAnswer}
                    questionIndex={questionIndex}
                />
                <button
                    onClick={processAnswer}
                    className="flex gap-x-2 items-center text-center rounded-lg cursor-pointer right-0 text-slate-400 text-xs bg-gray-800 hover:bg-gray-700 rounded py-2 px-4 transition duration-200"
                    title="Press Enter or click here to submit"
                >
                    Press Enter to submit
                </button>
            </div>
        </form>
    );
};
