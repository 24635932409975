import { client } from '../../../api/client';
import { callingJira, setJiraPercentage } from '../../reducers/generic/jira';
import { SYNC_JIRA } from '../../../api/graphQL/enterprise/mutator';

export const syncJira =
    ({ projectId, version, jiraProjectId }) =>
    async (dispatch, getState) => {
        try {
            dispatch(callingJira(true));

            let skillLevel = getState().sow.skillLevel;

            await client.mutate({
                mutation: SYNC_JIRA,
                variables: {
                    projectId,
                    version,
                    jiraProjectId,
                    skillLevel
                },
                fetchPolicy: 'network-only'
            });
        } catch (error) {
            console.log({ error });
            dispatch(callingJira(false));
            dispatch(setJiraPercentage(0));
            throw new Error(error.message);
        }
    };
