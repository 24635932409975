import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { CREATE_PROJECT } from '../../../api/graphQL';
import { useNavigate } from 'react-router-dom';
import { addToProjects } from '../../../redux/reducers/generic/projects';
import { ContinueButton } from '../../CreateNewProject';
import UploadRFP from '../../CreateNewProject/UploadRFP';
import NewExistingProjectPicker from '../../CreateNewProject/NewExistingProjectPicker';
import { useEffect } from 'react';

export const NewProjectInput = () => {
    const dispatch = useDispatch();
    const [newProjectName, setNewProjectName] = useState('');
    const [isLoading, setLoading] = useState(false);
    let [createProject] = useMutation(CREATE_PROJECT);
    const navigate = useNavigate();
    const [fileKey, setFileKey] = useState(null);
    const [option, setOption] = useState('new');

    const [isUploading, setUploading] = useState(false);

    const handleNewProjectSubmit = async e => {
        e.preventDefault();
        if (isUploading) return;
        if (newProjectName.trim() !== '') {
            setLoading(true);
            try {
                let new_project = await createProject({
                    variables: {
                        name: newProjectName,
                        type: 'other',
                        hasExistingContext: option === 'existing',
                        fileKeyS3: fileKey // pass the file key to the mutation
                    }
                });
                navigate(`/projects/${new_project.data.createProject._id}`);
                dispatch(addToProjects(new_project.data.createProject));
            } catch (error) {
                console.log({ error });
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            try {
                document.getElementById('createNewProject1').focus();
            } catch (err) {
                console.log(err);
            }
        }, 100);
    }, []);

    return (
        <div>
            <form
                onSubmit={handleNewProjectSubmit}
                className="mb-4 flex flex-col items-center justify-center"
            >
                <NewExistingProjectPicker
                    option={option}
                    setOption={setOption}
                />
                <input
                    id={'createNewProject1'}
                    type="text"
                    placeholder="Project Name"
                    value={newProjectName}
                    onChange={e => setNewProjectName(e.target.value)}
                    className=" border-gray-400 px-4 py-2 w-full outline-none mb-4"
                />

                <UploadRFP
                    setFileKey={setFileKey}
                    isUploading={isUploading}
                    setUploading={setUploading}
                />

                <div className="h-4" />
                <ContinueButton isLoading={isLoading} />
            </form>
        </div>
    );
};
