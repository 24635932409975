import { SUBMIT_PROJECT_TO_LEADERBOARD } from '../../../api/graphQL/solo/mutator';
import { client } from '../../../api/client';
import {
    fetchLeaderboardProjectEntryStart,
    setLeaderboardProjectEntry
} from '../../reducers/leaderboard';

export const submitProjectToLeaderboard =
    ({
        projectId,
        description,
        name
    }: {
        projectId: string;
        description: string;
        name: string;
    }) =>
    async (dispatch: any) => {
        try {
            dispatch(fetchLeaderboardProjectEntryStart());

            const { data } = await client.mutate({
                mutation: SUBMIT_PROJECT_TO_LEADERBOARD,
                variables: {
                    projectId,
                    description,
                    name
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                setLeaderboardProjectEntry(data.submitProjectToLeaderboard)
            );

            return 'Successfully submitted project to leaderboard.';
        } catch (error) {
            console.log({ error });
            return error.message;
        }
    };
