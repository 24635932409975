import React, { memo, ReactNode, useState, useEffect } from 'react';
import {
    Handle,
    NodeProps,
    Node,
    useReactFlow,
    useViewport,
    NodeToolbar,
    Position
} from '@xyflow/react';
import { FiCloud } from 'react-icons/fi';
import { useProject } from '../../../../context-providers/Project';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { selectFreeNodeLabel } from '../../../../redux/reducers/generic/projectArchitecture';
import { useSelector } from 'react-redux';

export type TurboNodeData = {
    label?: string;
    side?: string;
    description?: string;
    hours?: number;
    emoji?: string;
};

export default memo(({ data }: NodeProps<Node<TurboNodeData>>) => {
    const { zoom } = useViewport();

    const [showTooltip, setShowTooltip] = useState(false);
    const [showToolbar, setShowToolbar] = useState(false);

    const scale = data?.hours ? Math.min(Math.max(data.hours / 5, 1), 5) : 1;

    const { pageType } = useProject();
    const { isSubbed, tier } = useSubscriptionPayment();
    const freeNodeLabel = useSelector(selectFreeNodeLabel);

    let borderColor = 'gradient';
    let textColor = 'text-white';

    if (pageType === 'code') {
        if (data?.label === 'Core') {
            borderColor = 'border border-gray-700';
            textColor = 'text-gray-500';
        } else if (!isSubbed || tier === 'SoloPlus') {
            if (freeNodeLabel?.toLowerCase() !== data?.label?.toLowerCase()) {
                borderColor = 'border border-gray-700';
                textColor = 'text-gray-500';
            }
        }
    }

    return (
        <div
            className=""
            onMouseEnter={() => setShowToolbar(true)}
            onMouseLeave={() => setShowToolbar(false)}
        >
            <NodeToolbar isVisible={showToolbar} position={Position.Top}>
                <div className="backdrop-blur-lg rounded-lg p-8">
                    <div className="title">{data?.label}</div>
                    <div className="subline max-w-[200px] my-2">
                        {data?.description}
                    </div>
                </div>
            </NodeToolbar>
            <div className={`cloud ${borderColor}`}>
                <div>
                    <div className="text-[120px] p-6">
                        <FiCloud className="text-[120px] p-6" />
                    </div>
                </div>
            </div>
            <div
                className={`wrapper ${borderColor} rounded-lg ${
                    data?.label === 'Core' ? 'border border-gray-700' : ''
                }`}
            >
                <div className="inner">
                    <div className="body ml-8 mr-16 my-10">
                        <div className="mr-16">
                            {data?.side == 'user flow' ? (
                                <>
                                    {data?.emoji && (
                                        <span
                                            className={`text-8xl font-bold mt-8 ${textColor}`}
                                        >
                                            {data?.emoji}
                                        </span>
                                    )}
                                </>
                            ) : (
                                <FiCloud
                                    className={`text-8xl font-weight-800 mt-8 ${textColor}`}
                                />
                            )}
                        </div>
                        <div className="">
                            <div className={`text-8xl mt-8 mb-8 ${textColor}`}>
                                {data?.label}
                            </div>
                            {/* <div className="subline mb-4 max-w-[500px] my-2 text-3xl">{data?.description}</div> */}
                        </div>
                    </div>

                    <Handle type="target" position={Position.Left} />
                    <Handle type="source" position={Position.Right} />
                </div>
            </div>
        </div>
    );
});
