import { useState } from 'react';
import { Listbox } from '@headlessui/react';
import {
    CheckIcon,
    ChevronDownIcon,
    ArrowDownTrayIcon
} from '@heroicons/react/20/solid';
import { ArrowDown } from 'lucide-react';

const publishingOptions = [
    {
        title: 'Code',
        description: 'Export the code as a .zip file',
        current: true,
        onClick: 'onClickCode'
    },
    {
        title: 'Wireframe',
        description: 'Export a wireframe of the UI in .png format',
        current: false,
        onClick: 'onClickWireframe'
    }
];

export const Export = ({ onClickCode, onClickWireframe }) => {
    const [selected, setSelected] = useState(publishingOptions[0]);

    const handleOptionClick = option => {
        if (option.onClick === 'onClickCode') {
            onClickCode();
        } else if (option.onClick === 'onClickWireframe') {
            onClickWireframe();
        }
    };

    return (
        <div className="flex justify-end mb-4">
            <Listbox value={selected} onChange={setSelected}>
                <Listbox.Label className="sr-only">
                    Change published status
                </Listbox.Label>
                <div className="relative">
                    <div className="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
                        <Listbox.Button className="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm hover:bg-indigo-700">
                            <ArrowDownTrayIcon
                                aria-hidden="true"
                                className="-ml-0.5 h-5 w-5"
                            />
                            <p className="text-sm font-semibold">Export</p>
                        </Listbox.Button>
                        <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                            <span className="sr-only">
                                Change published status
                            </span>
                            <ChevronDownIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-white"
                            />
                        </Listbox.Button>
                    </div>

                    <Listbox.Options
                        transition
                        className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in"
                    >
                        {publishingOptions.map(option => (
                            <Listbox.Option
                                key={option.title}
                                value={option}
                                className="group cursor-pointer select-none p-4 text-sm text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white hover:bg-gray-100"
                                onClick={() => handleOptionClick(option)}
                            >
                                <div className="flex flex-col">
                                    <div className="flex justify-between">
                                        <p className="font-normal group-data-[selected]:font-semibold">
                                            {option.title}
                                        </p>
                                        <span className="text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                            <CheckIcon
                                                aria-hidden="true"
                                                className="h-5 w-5"
                                            />
                                        </span>
                                    </div>
                                    <p className="mt-2 text-gray-500 group-data-[focus]:text-indigo-200">
                                        {option.description}
                                    </p>
                                </div>
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </div>
            </Listbox>
        </div>
    );
};
