import { GET_PROJECT_DESCRIPTION } from '../../../api/graphQL/solo/query';
import { client } from '../../../api/client';

export const getProjectDescription =
    ({ projectId, randId }: { projectId: string; randId: string }) =>
    async (dispatch: any) => {
        try {
            const { data } = await client.query({
                query: GET_PROJECT_DESCRIPTION,
                variables: {
                    projectId,
                    randId
                },
                fetchPolicy: 'network-only'
            });

            return data.getProjectDescription;
        } catch (error) {
            console.error({ error });
            return error.message;
        }
    };
