import { useDispatch, useSelector } from 'react-redux';
import {
    selectEditMode,
    selectLoadingSowCustom,
    selectLoadingSowMVP,
    selectSow,
    setEditMode
} from '../../../../redux/reducers/generic/sow';
import { Icon } from '../TipTap/components/ui/Icon';
import { useEffect, useState, useRef } from 'react';

// New component for mode toggle
export const ModeToggle = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const isEditMode = useSelector(selectEditMode);
    const mvpLoading = useSelector(selectLoadingSowMVP);
    const customLoading = useSelector(selectLoadingSowCustom);
    const dispatch = useDispatch();
    const sow = useSelector(selectSow);
    const type = sow?.type || 'mvp';
    const isLoadingSow = type === 'mvp' ? mvpLoading : customLoading;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        !isLoadingSow && (
            <div className="relative" ref={dropdownRef}>
                <button
                    onClick={toggleDropdown}
                    className="flex items-center gap-x-2 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    {isEditMode ? (
                        <Icon name="User" className="mr-2 h-5 w-5" />
                    ) : (
                        <img
                            src="/predevblack.png"
                            alt="pre.dev"
                            className="w-5"
                        />
                    )}
                    <span className="hidden md:inline">
                        {isEditMode ? 'User Mode' : 'pre.dev Mode'}
                    </span>
                    <Icon name="ChevronDown" className="h-5 w-5" />
                </button>
                {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            <button
                                onClick={() => {
                                    if (isEditMode)
                                        dispatch(setEditMode(false));
                                    setIsDropdownOpen(false);
                                }}
                                className="flex flex-col items-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                role="menuitem"
                            >
                                <div className="flex items-center">
                                    <img
                                        src="/predevblack.png"
                                        alt="pre.dev"
                                        className="mr-3 w-5"
                                    />
                                    <span>pre.dev Mode</span>
                                </div>
                                <span className="text-xs text-gray-500 mt-1">
                                    Document Created by pre.dev
                                </span>
                            </button>
                            <button
                                onClick={() => {
                                    if (!isEditMode)
                                        dispatch(setEditMode(true));
                                    setIsDropdownOpen(false);
                                }}
                                className="flex flex-col items-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                role="menuitem"
                            >
                                <div className="flex items-center">
                                    <Icon name="User" className="mr-3 w-5" />
                                    <span>User Mode</span>
                                </div>
                                <span className="text-xs text-gray-500 mt-1">
                                    Document Edited by User
                                </span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        )
    );
};
