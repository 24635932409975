import { ChatHistory } from './ChatHistory';
import { ChatInput } from './ChatInput';
import { useProject } from '../../../context-providers/Project';
import { ExistingProjectContext } from './ExistingProjectContext';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { useSelector } from 'react-redux';
import { selectProjectInternalVersion } from '../../../redux/reducers/generic/project';
import { MigrationBanner } from './migration';

export const Chat = ({ setShowDemoBanner }) => {
    let { pageType } = useProject();
    const projectInternalVersion = useSelector(selectProjectInternalVersion);

    const { isSolo, isEnterprise, isShare } = useSubscriptionPayment();

    return (
        pageType == 'chat' && (
            <>
                <ExistingProjectContext />
                <div className="dark overflow-y-scroll">
                    <ChatHistory isSolo={isSolo} />
                </div>
                {projectInternalVersion == 2 ? (
                    <ChatInput
                        setShowDemoBanner={setShowDemoBanner}
                        isSolo={isSolo}
                        isEnterprise={isEnterprise}
                        isShare={isShare}
                    />
                ) : (
                    <MigrationBanner />
                )}
            </>
        )
    );
};
