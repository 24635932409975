import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTimeLeaderboard } from '../../redux/actions/leaderboard/get_all_time_leaderboard';
import { getMonthlyLeaderboard } from '../../redux/actions/leaderboard/get_monthly_leaderboard';
import { getWeeklyLeaderboard } from '../../redux/actions/leaderboard/get_weekly_leaderboard';
import {
    selectLeaderboardEntry,
    selectLeaderboardList,
    selectPaginateLoading,
    selectTotalEntries
} from '../../redux/reducers/leaderboard';
import { LeaderboardEntry } from './LeaderboardEntry';
import { useSearchParams } from 'react-router-dom';
import { getLeaderboardEntry } from '../../redux/actions/leaderboard/get_leaderboard_entry';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import AnimatedGradientText from '../Components/magicui/AnimatedShinyText';
import FeatureLeaderboard from './feature_leaderboard';

const Leaderboard = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    let leaderboardEntryId = searchParams.get('leaderboardEntryId');
    const leaderboardEntry = useSelector(selectLeaderboardEntry);

    useEffect(() => {
        dispatch(getLeaderboardEntry(leaderboardEntryId));
    }, [leaderboardEntryId]);

    if (!leaderboardEntry && leaderboardEntryId) {
        return (
            <div className="max-w-screen-xl min-h-screen mx-auto p-4 bg-black mt-[56px]" />
        );
    }

    if (leaderboardEntry && leaderboardEntryId) {
        return (
            <div className="max-w-screen-xl min-h-screen mx-auto p-4 bg-black mt-[56px]">
                <button
                    onClick={() => {
                        setSearchParams({
                            timeFrame:
                                searchParams.get('timeFrame') || 'all-time'
                        });
                    }}
                    className="mb-4 px-4 py-2 rounded-lg bg-gray-700 text-white hover:bg-gray-600"
                >
                    Back
                </button>
                <LeaderboardEntry entry={leaderboardEntry} />
            </div>
        );
    }

    return <LeaderboardList />;
};

const LeaderboardList = () => {
    const dispatch = useDispatch();
    const leaderboardProjects = useSelector(selectLeaderboardList);
    const [loading, setLoading] = useState(true);
    const loadingMore = useSelector(selectPaginateLoading);
    const [searchParams, setSearchParams] = useSearchParams();
    const totalEntries = useSelector(selectTotalEntries);
    const loadMoreRef = useRef();

    let timeFrame = searchParams.get('timeFrame') || 'all-time';
    let sortField = searchParams.get('sortField') || 'submittedAt';
    let sortDesc =
        searchParams.get('sortDesc') === 'true' ||
        !searchParams.get('sortDesc');
    let skip = leaderboardProjects?.length || 0; // Calculate skip based on current projects length

    const fetchLeaderboard = async (loadMore = false) => {
        let limit = 10;
        console.log({
            loadingMore,
            loadMore,
            skip,
            totalEntries,
            leaderboardProjects
        });
        if (loadingMore) return; // Prevent multiple fetches
        if (loadMore && totalEntries <= leaderboardProjects?.length) return;
        if (timeFrame === 'monthly') {
            await dispatch(
                getMonthlyLeaderboard(
                    limit,
                    loadMore ? skip : 0,
                    sortField,
                    sortDesc,
                    loadMore
                )
            );
        } else if (timeFrame === 'weekly') {
            await dispatch(
                getWeeklyLeaderboard(
                    limit,
                    loadMore ? skip : 0,
                    sortField,
                    sortDesc,
                    loadMore
                )
            );
        } else if (timeFrame === 'all-time') {
            await dispatch(
                getAllTimeLeaderboard(
                    limit,
                    loadMore ? skip : 0,
                    sortField,
                    sortDesc,
                    loadMore
                )
            );
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchLeaderboard(); // Fetch leaderboard projects based on the selected timeframe
    }, [timeFrame, sortField, sortDesc]);

    const handleLoadMore = () => {
        if (!loadingMore) {
            fetchLeaderboard(true);
        }
    };

    const observer = useRef();
    const loadMoreButtonRef = useCallback(
        node => {
            if (loadingMore) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    // handleLoadMore();
                }
            });
            if (node) {
                observer.current.observe(node);
            } else {
                observer.current = null;
            }
        },
        [loadingMore]
    );
    const handleScroll = () => {
        console.log(
            document.documentElement.scrollTop + window.innerHeight + 500,
            document.documentElement.scrollHeight
        );
        if (
            document.documentElement.scrollTop + window.innerHeight + 500 <
            document.documentElement.scrollHeight
        )
            return;

        handleLoadMore();
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loadingMore, leaderboardProjects]);

    return (
        <>
            <div className="max-w-screen-xl min-h-screen mx-auto p-4 bg-black mt-[56px]">
                <h1 className="text-2xl font-bold text-white mb-2">
                    {timeFrame?.charAt(0).toUpperCase() + timeFrame?.slice(1)}{' '}
                    Leaderboard
                </h1>
                <div className="mb-12 mt-8 rounded-xl shadow-2xl overflow-hidden">
                    <AnimatedGradientText className="flex flex-col p-8 bg-gradient-to-r from-gray-800 to-gray-900">
                        <div className="mb-6 text-center mt-4">
                            <span className="text-2xl font-bold inline-flex items-center justify-center animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent">
                                🎉 Exciting rewards for our winners:
                            </span>
                        </div>
                        <ul className="space-y-4 mb-8 text-white">
                            <li className="flex items-start">
                                <span className="mr-2 text-yellow-400">🏆</span>
                                <span className="text-lg">
                                    <span className="font-semibold">
                                        Weekly winner:
                                    </span>{' '}
                                    A one-month subscription to{' '}
                                    <span className="font-bold text-blue-400">
                                        pre.dev Solo Premium
                                    </span>
                                </span>
                            </li>
                            <li className="flex items-start">
                                <span className="mr-2 text-purple-400">🚀</span>
                                <span className="text-lg">
                                    <span className="font-semibold">
                                        Monthly winner:
                                    </span>{' '}
                                    A free front-end prototype for their project
                                </span>
                            </li>
                        </ul>
                        <div className="flex justify-center items-center mb-4">
                            <a
                                href="/projects"
                                className="inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white text-lg font-bold rounded-full shadow-lg hover:from-blue-700 hover:to-purple-700 transition-all duration-300 ease-in-out transform hover:scale-105"
                            >
                                Add Your Own Project
                            </a>
                        </div>
                    </AnimatedGradientText>
                </div>
                <div className="mb-8">
                    <FeatureLeaderboard />
                </div>

                <TimeFrameButtons
                    timeFrame={timeFrame}
                    sortField={sortField}
                    sortDesc={sortDesc}
                />
                <div className="mb-4 flex space-x-4">
                    <SortOptions
                        sortField={sortField}
                        timeFrame={timeFrame}
                        sortDesc={sortDesc}
                    />
                </div>
                {loading ? (
                    <p className="text-gray-500">Loading...</p>
                ) : (
                    <div className="flex flex-col gap-8">
                        {leaderboardProjects &&
                        leaderboardProjects.length > 0 ? (
                            leaderboardProjects.map(project => (
                                <div key={project._id} className="text-white">
                                    <LeaderboardEntry entry={project} />
                                </div>
                            ))
                        ) : (
                            <div className="text-gray-500">
                                No projects available.
                            </div>
                        )}
                    </div>
                )}
                {totalEntries > leaderboardProjects?.length && (
                    <button
                        ref={loadMoreButtonRef}
                        onClick={handleLoadMore}
                        className={`mt-4 px-4 py-2 rounded-lg ${
                            loadingMore
                                ? 'bg-gray-500 text-gray-300'
                                : 'bg-gray-700 text-white hover:bg-gray-600'
                        }`}
                        disabled={loadingMore}
                    >
                        {loadingMore ? 'Loading more...' : 'Load More'}
                    </button>
                )}
            </div>
        </>
    );
};

const TimeFrameButtons = ({ timeFrame }) => (
    <div className="mb-4 flex space-x-4">
        {[
            {
                label: 'All Time',
                frame: 'all-time'
            },
            {
                label: 'Monthly',
                frame: 'monthly'
            },
            {
                label: 'Weekly',
                frame: 'weekly'
            }
        ].map(({ label, frame }) => (
            <LeaderboardButton
                key={frame}
                label={label}
                frame={frame}
                isActive={timeFrame === frame}
            />
        ))}
    </div>
);

const LeaderboardButton = ({ label, frame, isActive }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const onClick = () => {
        console.log({ frame });
        setSearchParams({
            ...Object.fromEntries(searchParams),
            timeFrame: frame
        });
    };
    return (
        <button
            onClick={onClick}
            className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                isActive
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-700 text-gray-300'
            } hover:bg-blue-500`}
        >
            {label}
        </button>
    );
};

const SortOptions = ({ sortField, sortDesc }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSortChange = (newField, newDesc) => {
        setSearchParams({
            ...Object.fromEntries(searchParams),
            sortField: newField,
            sortDesc: newDesc
        });
    };

    return [
        {
            label: 'Date',
            field: 'submittedAt'
        },
        {
            label: 'Upvotes',
            field: 'upvotes'
        }
    ].map(({ label, field }) => (
        <SortButton
            key={field}
            label={label}
            field={field}
            isActive={sortField === field}
            isDesc={sortDesc}
            handleSortChange={handleSortChange}
        />
    ));
};

const SortButton = ({ label, field, isDesc, handleSortChange, isActive }) => (
    <div className="mb-4 flex space-x-4">
        <button
            onClick={() => handleSortChange(field, isActive ? !isDesc : true)}
            className={`flex items-center px-4 py-2 rounded-lg  ${
                isActive ? 'opacity-100' : 'opacity-50'
            } transition-opacity duration-200 text-white`}
        >
            <div className="flex items-center">
                {isDesc ? (
                    <FaArrowDown className="mr-1 text-gray-500" />
                ) : (
                    <FaArrowUp className="mr-1 text-gray-500" />
                )}
            </div>
            {label}
        </button>
    </div>
);

export default Leaderboard;
