import { GET_LEADERBOARD_ENTRY } from '../../../api/graphQL/solo/query';
import { client } from '../../../api/client';
import leaderboard, {
    fetchLeaderboardEntryStart,
    setLeaderboardEntry
} from '../../reducers/leaderboard';

export const getLeaderboardEntry =
    (leaderboardEntryId: string) => async (dispatch: any) => {
        try {
            dispatch(fetchLeaderboardEntryStart());

            console.log({ leaderboardEntryId });
            const { data } = await client.query({
                query: GET_LEADERBOARD_ENTRY,
                variables: {
                    leaderboardEntryId
                },
                fetchPolicy: 'network-only'
            });

            dispatch(setLeaderboardEntry(data.getLeaderboardEntry));

            return 'Successfully fetched leaderboard entry.';
        } catch (error) {
            console.error({ error });
            dispatch(setLeaderboardEntry(null));
            return error.message;
        }
    };
