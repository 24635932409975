import { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { EditSubtaskForm } from './EditSubTaskForm';
import { useDispatch } from 'react-redux';
import { deleteSubTask } from '../../../../redux/actions/solo/deleteSubTask';
import { editSubTask } from '../../../../redux/actions/solo/editSubTask';
import { addSubTask } from '../../../../redux/actions/solo/addSubTask';

export const SubtasksDialog = ({
    showSubtasksDialog,
    setShowSubtasksDialog,
    setOpen,
    subtasks,
    storyId,
    projectId,
    roles
}) => {
    const [editingSubtask, setEditingSubtask] = useState(null);
    const [updatedSubtask, setUpdateSubtask] = useState(null);
    const [localSubtasks, setLocalSubtasks] = useState(subtasks);
    const [localRoles, setLocalRoles] = useState([]);
    const [hoveredSubtaskIndex, setHoveredSubtaskIndex] = useState(null);
    const dispatch: any = useDispatch();

    useEffect(() => {
        setLocalSubtasks(subtasks);
        console.log(subtasks);
    }, [subtasks]);

    useEffect(() => {
        if (editingSubtask) {
            setLocalRoles(editingSubtask.roles);
        }
    }, [editingSubtask]);

    const handleSave = () => {
        setLocalSubtasks(prevSubtasks => {
            const updatedSubtaskWithRoles = {
                ...updatedSubtask,
                roles: localRoles
            };

            // Filter out the subtask being updated
            const filteredSubtasks = prevSubtasks.filter(
                subtask => subtask._id !== updatedSubtaskWithRoles._id
            );

            // Adjust indices of subtasks greater than or equal to the updated subtask's index
            const adjustedSubtasks = filteredSubtasks.map(subtask => {
                if (subtask.index >= updatedSubtaskWithRoles.index) {
                    return { ...subtask, index: subtask.index + 1 };
                }
                return subtask;
            });

            // Insert the updated subtask at its new index
            adjustedSubtasks.splice(
                updatedSubtaskWithRoles.index,
                0,
                updatedSubtaskWithRoles
            );
            return adjustedSubtasks;
        });
        const updatedSubtaskWithRoles = {
            ...updatedSubtask,
            roles: localRoles
        };

        if (editingSubtask.new) {
            console.log({
                storyId: storyId,
                projectId: projectId,
                description: updatedSubtaskWithRoles.description,
                complexityEstimate: updatedSubtaskWithRoles.complexityEstimate,
                hours: parseInt(updatedSubtaskWithRoles.hours),
                roles: updatedSubtaskWithRoles.roles,
                index: updatedSubtaskWithRoles.index
            });
            dispatch(
                addSubTask({
                    storyId: storyId,
                    projectId: projectId,
                    description: updatedSubtaskWithRoles.description,
                    complexityEstimate:
                        updatedSubtaskWithRoles.complexityEstimate,
                    hours: parseInt(updatedSubtaskWithRoles.hours),
                    roles: updatedSubtaskWithRoles.roles,
                    index: updatedSubtaskWithRoles.index
                })
            );
        } else {
            console.log({
                subTaskId: updatedSubtaskWithRoles._id,
                description: updatedSubtaskWithRoles.description,
                complexityEstimate: updatedSubtaskWithRoles.complexityEstimate,
                hours: parseInt(updatedSubtaskWithRoles.hours),
                roles: updatedSubtaskWithRoles.roles,
                index: updatedSubtaskWithRoles.index
            });
            dispatch(
                editSubTask({
                    subTaskId: updatedSubtaskWithRoles._id,
                    description: updatedSubtaskWithRoles.description,
                    complexityEstimate:
                        updatedSubtaskWithRoles.complexityEstimate,
                    hours: parseInt(updatedSubtaskWithRoles.hours),
                    roles: updatedSubtaskWithRoles.roles,
                    index: updatedSubtaskWithRoles.index
                })
            );
        }

        setEditingSubtask(null);
    };

    const handleDeleteSubtask = subTaskId => {
        console.log(`Deleting subtask with ID: ${subTaskId}`);
        setLocalSubtasks(prevSubtasks => {
            const updatedSubtasks = prevSubtasks.filter(
                subtask => subtask._id !== subTaskId
            );
            // Update the index of remaining subtasks
            return updatedSubtasks.map((subtask, index) => ({
                ...subtask,
                index
            }));
        });
        dispatch(deleteSubTask({ subTaskId }));
    };
    const handleDragEnd = result => {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        )
            return;

        const targetSubTask = localSubtasks[result.source.index];

        const reorderedSubtasks = Array.from(localSubtasks);
        const [movedSubtask] = reorderedSubtasks.splice(result.source.index, 1);
        reorderedSubtasks.splice(result.destination.index, 0, movedSubtask);

        setLocalSubtasks(
            reorderedSubtasks.map((subtask, idx) => {
                if (typeof subtask === 'object' && subtask !== null) {
                    return { ...subtask, index: idx };
                }
                return subtask;
            })
        );

        console.log({
            subTaskId: targetSubTask._id,
            description: targetSubTask.description,
            complexityEstimate: targetSubTask.complexityEstimate,
            hours: parseInt(targetSubTask.hours),
            roles: targetSubTask.roles,
            index: result.destination.index
        });
        dispatch(
            editSubTask({
                subTaskId: targetSubTask._id,
                description: targetSubTask.description,
                complexityEstimate: targetSubTask.complexityEstimate,
                hours: parseInt(targetSubTask.hours),
                roles: targetSubTask.roles.map(role => ({
                    name: role.name,
                    shortHand: role.shortHand
                })),
                index: result.destination.index
            })
        );
    };

    const handleAddNewSubtask = index => {
        const newSubtask = {
            new: true,
            description: '',
            complexityEstimate: 'Straightforward',
            hours: 0,
            roles: [],
            index
        };
        setEditingSubtask(newSubtask);
    };

    return (
        <>
            {showSubtasksDialog && (
                <div
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClick={e => {
                        e.stopPropagation();
                        setOpen(true);
                    }}
                >
                    <div className="flex items-center justify-center min-h-screen text-center sm:block sm:p-0">
                        <div
                            className="fixed inset-0 transition-opacity"
                            aria-hidden="true"
                        >
                            <div className="absolute inset-0 bg-black opacity-75"></div>
                        </div>
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <div className="inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                        <div className="flex justify-between">
                                            <h3 className="text-lg leading-6 font-medium text-white">
                                                {editingSubtask
                                                    ? 'Edit Subtask'
                                                    : 'Subtasks'}
                                            </h3>
                                            <button
                                                type="button"
                                                className="text-gray-400 hover:text-gray-500 focus:outline-none"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    if (editingSubtask) {
                                                        setEditingSubtask(null);
                                                        setOpen(true);
                                                    } else {
                                                        setShowSubtasksDialog(
                                                            false
                                                        );
                                                        setOpen(true);
                                                    }
                                                }}
                                            >
                                                <XMarkIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                        <div className="mt-2 overflow-y-auto relative">
                                            {editingSubtask ? (
                                                <EditSubtaskForm
                                                    subtask={editingSubtask}
                                                    roles={localRoles}
                                                    subtasksLength={
                                                        subtasks.length
                                                    }
                                                    setUpdateSubtask={
                                                        setUpdateSubtask
                                                    }
                                                    setLocalRoles={
                                                        setLocalRoles
                                                    }
                                                    allRoles={roles}
                                                    subtasks={subtasks}
                                                />
                                            ) : (
                                                <DragDropContext
                                                    onDragEnd={handleDragEnd}
                                                >
                                                    <Droppable droppableId="subtasks">
                                                        {provided => (
                                                            <ul
                                                                className={`list-disc pl-5 space-y-4 py-8`}
                                                                {...provided.droppableProps}
                                                                onMouseLeave={() =>
                                                                    setHoveredSubtaskIndex(
                                                                        null
                                                                    )
                                                                }
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                            >
                                                                {localSubtasks.map(
                                                                    (
                                                                        subtask,
                                                                        index
                                                                    ) => (
                                                                        <Draggable
                                                                            key={
                                                                                index
                                                                            }
                                                                            draggableId={subtask?.index?.toString()}
                                                                            index={
                                                                                index
                                                                            }
                                                                        >
                                                                            {provided => (
                                                                                <li
                                                                                    ref={
                                                                                        provided.innerRef
                                                                                    }
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    className={`text-sm text-gray-400 bg-transparent border-none p-2 w-full focus:bg-gray-800 focus:outline-none focus:ring-0 focus:border-transparent hover:bg-gray-900 transition-colors duration-200 rounded-md cursor-pointer relative`}
                                                                                    style={{
                                                                                        ...provided
                                                                                            .draggableProps
                                                                                            .style
                                                                                    }} // Adjust the marginRight value as needed
                                                                                    onClick={() =>
                                                                                        setEditingSubtask(
                                                                                            subtask
                                                                                        )
                                                                                    }
                                                                                    onMouseEnter={() =>
                                                                                        setHoveredSubtaskIndex(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <span className="font-semibold text-white">
                                                                                        {
                                                                                            subtask?.description
                                                                                        }
                                                                                    </span>
                                                                                    <div className="flex flex-wrap mt-2 space-x-2">
                                                                                        <span className="mb-2 bg-purple-500 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-md">
                                                                                            Hours:{' '}
                                                                                            {
                                                                                                subtask?.hours
                                                                                            }
                                                                                        </span>
                                                                                        <span className="mb-2 bg-red-500 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-md">
                                                                                            Complexity:{' '}
                                                                                            {
                                                                                                subtask?.complexityEstimate
                                                                                            }
                                                                                        </span>
                                                                                        {subtask?.roles?.map(
                                                                                            (
                                                                                                role,
                                                                                                i
                                                                                            ) => (
                                                                                                <span
                                                                                                    key={
                                                                                                        i
                                                                                                    }
                                                                                                    className="mb-2 bg-yellow-500 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-md"
                                                                                                >
                                                                                                    {
                                                                                                        role.name
                                                                                                    }{' '}
                                                                                                    (
                                                                                                    {
                                                                                                        role.shortHand
                                                                                                    }

                                                                                                    )
                                                                                                </span>
                                                                                            )
                                                                                        )}
                                                                                        {subtask?.frontendPageCitations?.map(
                                                                                            (
                                                                                                citation,
                                                                                                i
                                                                                            ) => (
                                                                                                <span
                                                                                                    key={
                                                                                                        i
                                                                                                    }
                                                                                                    className={`mb-2 bg-green-500 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-md ${
                                                                                                        i ===
                                                                                                        0
                                                                                                            ? 'h-auto'
                                                                                                            : ''
                                                                                                    }`}
                                                                                                >
                                                                                                    {
                                                                                                        citation
                                                                                                    }
                                                                                                </span>
                                                                                            )
                                                                                        )}
                                                                                        {subtask?.architectureNodeIdCitations?.map(
                                                                                            (
                                                                                                citation,
                                                                                                i
                                                                                            ) => (
                                                                                                <span
                                                                                                    key={
                                                                                                        i
                                                                                                    }
                                                                                                    className={`mb-2 bg-blue-500 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-md ${
                                                                                                        i ===
                                                                                                        0
                                                                                                            ? 'h-auto'
                                                                                                            : ''
                                                                                                    }`}
                                                                                                >
                                                                                                    {
                                                                                                        citation
                                                                                                    }
                                                                                                </span>
                                                                                            )
                                                                                        )}
                                                                                        {subtask?.techStackItemCitations?.map(
                                                                                            (
                                                                                                citation,
                                                                                                i
                                                                                            ) => (
                                                                                                <span
                                                                                                    key={
                                                                                                        i
                                                                                                    }
                                                                                                    className={`mb-2 bg-orange-500 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-md ${
                                                                                                        i ===
                                                                                                        0
                                                                                                            ? 'h-auto'
                                                                                                            : ''
                                                                                                    }`}
                                                                                                >
                                                                                                    {
                                                                                                        citation
                                                                                                    }
                                                                                                </span>
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                    {hoveredSubtaskIndex ===
                                                                                        index && (
                                                                                        <>
                                                                                            <div
                                                                                                className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-gray-700 rounded-full p-1 cursor-pointer hover:bg-gray-600 z-20"
                                                                                                onClick={e => {
                                                                                                    e.stopPropagation();
                                                                                                    handleAddNewSubtask(
                                                                                                        index
                                                                                                    );
                                                                                                    setShowSubtasksDialog(
                                                                                                        true
                                                                                                    );
                                                                                                    setOpen(
                                                                                                        true
                                                                                                    );
                                                                                                }}
                                                                                                style={{
                                                                                                    transform:
                                                                                                        'translate(-50%, -50%)'
                                                                                                }}
                                                                                            >
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    className="h-5 w-5 text-gray-400"
                                                                                                    viewBox="0 0 20 20"
                                                                                                    fill="currentColor"
                                                                                                >
                                                                                                    <path
                                                                                                        fillRule="evenodd"
                                                                                                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                                                                                        clipRule="evenodd"
                                                                                                    />
                                                                                                </svg>
                                                                                            </div>
                                                                                            <div
                                                                                                className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 bg-gray-700 rounded-full p-1 cursor-pointer hover:bg-gray-600 z-20"
                                                                                                onClick={e => {
                                                                                                    e.stopPropagation();
                                                                                                    handleAddNewSubtask(
                                                                                                        index +
                                                                                                            1
                                                                                                    );
                                                                                                    setShowSubtasksDialog(
                                                                                                        true
                                                                                                    );
                                                                                                    setOpen(
                                                                                                        true
                                                                                                    );
                                                                                                }}
                                                                                                style={{
                                                                                                    transform:
                                                                                                        'translate(-50%, 50%)'
                                                                                                }}
                                                                                            >
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    className="h-5 w-5 text-gray-400"
                                                                                                    viewBox="0 0 20 20"
                                                                                                    fill="currentColor"
                                                                                                >
                                                                                                    <path
                                                                                                        fillRule="evenodd"
                                                                                                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                                                                                        clipRule="evenodd"
                                                                                                    />
                                                                                                </svg>
                                                                                            </div>
                                                                                            <button
                                                                                                className="mr-8 mb-2 absolute bottom-0 right-0 transform translate-x-1/2 bg-red-600 text-white rounded-full p-2 hover:bg-red-500"
                                                                                                onClick={e => {
                                                                                                    e.stopPropagation();
                                                                                                    if (
                                                                                                        window.confirm(
                                                                                                            'Are you sure you want to delete this subtask?'
                                                                                                        )
                                                                                                    ) {
                                                                                                        handleDeleteSubtask(
                                                                                                            subtask._id
                                                                                                        ); // Assuming subtask has an id property
                                                                                                    }
                                                                                                    setShowSubtasksDialog(
                                                                                                        true
                                                                                                    );
                                                                                                    setOpen(
                                                                                                        true
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <XMarkIcon className="h-6 w-6" />
                                                                                            </button>
                                                                                        </>
                                                                                    )}
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    )
                                                                )}
                                                                {
                                                                    provided.placeholder
                                                                }
                                                            </ul>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            )}
                                            <div className="fixed bottom-20 right-2 p-1 bg-gray-700 rounded-full">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 text-gray-400"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 111.414 1.414l-4 4a1 1 01-1.414 0l-4-4a1 1 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                {editingSubtask ? (
                                    <>
                                        <button
                                            type="button"
                                            className="mb-2 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-700 text-base font-medium text-gray-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleSave();
                                                setShowSubtasksDialog(true);
                                                setOpen(true);
                                            }}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="mb-2 mr-2 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-700 text-base font-medium text-gray-300 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={e => {
                                                e.stopPropagation();
                                                setEditingSubtask(null);
                                                setShowSubtasksDialog(true);
                                                setOpen(true);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-gray-300 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={e => {
                                            e.stopPropagation();
                                            setShowSubtasksDialog(false);
                                            setOpen(true);
                                        }}
                                    >
                                        Close
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
