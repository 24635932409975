import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllTimeLeaderboard } from '../../redux/actions/leaderboard/get_all_time_leaderboard';
import { motion } from 'framer-motion';
import { FaTrophy, FaMedal, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FeatureLeaderboard = () => {
    const dispatch = useDispatch();
    const [topThree, setTopThree] = useState([]);

    useEffect(() => {
        const fetchTopThree = async () => {
            try {
                const topThreeProjects = await dispatch(
                    getAllTimeLeaderboard(3, 0, 'upvotes', true, false, true)
                );
                setTopThree(topThreeProjects.entries);
            } catch (error) {
                console.error('Error fetching top three projects:', error);
            }
        };

        fetchTopThree();
    }, [dispatch]);

    const getIcon = index => {
        switch (index) {
            case 0:
                return <FaTrophy className="text-yellow-400 text-3xl" />;
            case 1:
                return <FaMedal className="text-gray-400 text-3xl" />;
            case 2:
                return <FaMedal className="text-amber-600 text-3xl" />;
            default:
                return null;
        }
    };

    return (
        <div className="bg-gradient-to-br from-gray-900 to-gray-800 p-6 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-white">
                    Featured Projects
                </h2>
                {window.location.pathname === '/' && (
                    <Link
                        to="/leaderboard"
                        className="flex items-center text-blue-400 hover:text-blue-300 transition duration-300"
                        onClick={() => window.scrollTo(0, 0)}
                    >
                        View Full Leaderboard
                        <FaChevronRight className="ml-2" />
                    </Link>
                )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {topThree.map((project, index) => (
                    <motion.div
                        key={project._id}
                        className="bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.2 }}
                    >
                        <div className="flex items-center justify-between mb-2">
                            <h3 className="text-lg font-semibold text-white truncate">
                                {project.name}
                            </h3>
                            {getIcon(index)}
                        </div>
                        <p className="text-gray-300 mb-3 text-sm line-clamp-2">
                            {project.description}
                        </p>
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-400">
                                Upvotes: {project.upvotes}
                            </span>
                            <Link
                                to={`/leaderboard?leaderboardEntryId=${project._id}`}
                                className="px-3 py-1 bg-blue-600 text-white text-sm rounded hover:bg-blue-700 transition duration-300 transform hover:scale-105"
                            >
                                View Details
                            </Link>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default FeatureLeaderboard;
