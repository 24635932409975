import { Story } from './story';
import { userJourney } from './userJourney';
import { motion } from 'framer-motion';
import { useRef, useEffect, useState } from 'react';

export function HowItWorks() {
    const [inViews, setInViews] = useState<boolean[]>(
        Array(userJourney.steps.length).fill(false)
    );
    const refs = useRef<(HTMLDivElement | null)[]>([]);
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observers = userJourney.steps.map((_, index) => {
            return new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setInViews(prev => {
                            const newInViews = [...prev];
                            newInViews[index] = true;
                            return newInViews;
                        });
                    }
                },
                { threshold: 0.1 }
            );
        });

        refs.current.forEach((ref, index) => {
            if (ref) observers[index].observe(ref);
        });

        return () => {
            observers.forEach(observer => observer.disconnect());
        };
    }, []);

    return (
        <div id="how-it-works" className="mb-32 dark pt-8">
            <div className="mx-auto max-w-8xl">
                <div className="text-center pt-16 mb-16">
                    <h2 className="text-4xl font-bold tracking-tight text-black dark:text-white sm:text-6xl">
                        How <span className="text-blue-600">pre.dev</span> works
                    </h2>
                    <p className="text-lg text-gray-600 dark:text-gray-300 mt-4 max-w-3xl mx-auto">
                        Discover the seamless journey from idea to
                        implementation with pre.dev. Our AI-powered platform
                        streamlines your product development process, making it
                        faster and more efficient than ever before.
                    </p>
                </div>
                <div className="flex flex-col gap-y-36">
                    {userJourney.steps.map((step, index) => (
                        <>
                            {index === 0 ? (
                                <Story story={step} />
                            ) : (
                                // <motion.div
                                //     key={index}
                                //     ref={el => (refs.current[index] = el)}
                                //     initial={{ opacity: 0, y: 50 }}
                                //     animate={
                                //         inViews[index]
                                //             ? { opacity: 1, y: 0 }
                                //             : { opacity: 0, y: 50 }
                                //     }
                                //     transition={{ duration: 0.5, delay: 0.2 }}
                                // >
                                <Story story={step} />
                                // </motion.div>
                            )}
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
}
