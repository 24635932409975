import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { voteOnLeaderboardEntry } from '../../redux/actions/leaderboard/vote_on_leaderboard_entry';
import { commentOnLeaderboardEntry } from '../../redux/actions/leaderboard/comment_on_leaderboard_entry';
import { removeProjectFromLeaderboard } from '../../redux/actions/leaderboard/remove_project_from_leaderboard';
import { reportLeaderboardEntry } from '../../redux/actions/leaderboard/report_leaderboard_entry';
import {
    FaArrowUp,
    FaComment,
    FaTrash,
    FaShareAlt,
    FaFlag
} from 'react-icons/fa'; // Added FaShareAlt for sharing and FaFlag for reporting
import { useProject } from '../../context-providers/Project';
import { Comment } from './Comment';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { fetchLeaderboardEntryStart } from '../../redux/reducers/leaderboard';
import { toast } from 'sonner';
import { getWebsiteDomain } from '../../auth_config';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export const LeaderboardEntry = ({ entry }) => {
    const dispatch = useDispatch();
    const { projectId } = useProject();
    const { doesSessionExist } = useSessionContext();
    const isLoggedIn = doesSessionExist;

    return (
        <div className="p-4 border border-gray-700 rounded-lg shadow-md bg-black text-white flex flex-col ">
            <div className="gap-x-4 items-center w-full">
                <div>
                    <h3 className="text-xl font-bold">{entry.name}</h3>
                    <p className="text-gray-300 w-full">{entry.description}</p>
                    <p className="text-gray-400 text-sm mt-1">
                        Submitted {moment(entry.submittedAt).fromNow()}
                    </p>
                    <div className="flex flex-row gap-x-2 items-center mt-2 justify-between">
                        <div className="flex flex-row gap-x-2 items-center">
                            <UpvoteButton
                                entryId={entry._id}
                                isVotedByUser={entry.isVotedByUser}
                                upvotes={entry.upvotes}
                                isLoggedIn={isLoggedIn}
                            />
                            <CommentButton
                                leaderboardEntryId={entry._id}
                                commentCount={entry.commentCount}
                                comments={entry.comments}
                            />
                            <ShareButton entryId={entry._id} />{' '}
                        </div>
                        {entry.isMyEntry && (
                            <RemoveButton projectId={projectId} />
                        )}
                        {!entry.isMyEntry && (
                            <ReportButton
                                entryId={entry._id}
                                isLoggedIn={isLoggedIn}
                            />
                        )}
                    </div>
                </div>
            </div>

            {entry.comments && (
                <CommentInput entryId={entry._id} isLoggedIn={isLoggedIn} />
            )}

            {entry?.comments && (
                <div className="flex flex-col gap-y-4 mt-4">
                    {entry.comments.map(comment => (
                        <Comment
                            key={comment._id}
                            comment={comment}
                            depth={1}
                            isLoggedIn={isLoggedIn}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const ShareButton = ({ entryId }) => {
    const shareLink = `${getWebsiteDomain()}/leaderboard?leaderboardEntryId=${entryId}`;

    const handleShare = () => {
        navigator.clipboard
            .writeText(shareLink)
            .then(() => {
                toast.success('Link copied to clipboard!');
            })
            .catch(err => {
                toast.error('Failed to copy link.');
            });
    };

    return (
        <button
            onClick={handleShare}
            className="flex gap-x-2 flex-row items-center px-2 py-1 rounded-lg shadow-md bg-black hover:shadow-lg transition-shadow duration-200 h-fit hover:bg-gray-700 text-white"
        >
            <FaShareAlt className="text-blue-500 hover:text-blue-700 transition-colors duration-200" />
            Share
        </button>
    );
};
const ReportButton = ({ entryId, isLoggedIn }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleReport = async () => {
        if (!isLoggedIn) {
            toast.error('You must be logged in to report.');
            return;
        }
        setLoading(true);
        let message = await dispatch(reportLeaderboardEntry(entryId));
        toast.success(message);
        setLoading(false);
    };

    return (
        <button
            onClick={handleReport}
            className="flex flex-row items-center px-3 py-1 rounded-lg shadow-md bg-gray-600 hover:shadow-lg transition-shadow duration-200 h-fit hover:bg-gray-700 text-white relative"
            disabled={loading}
        >
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-600 bg-opacity-75">
                    <div className="loader"></div>
                </div>
            )}
            <FaFlag className="mr-1" />
            Report
        </button>
    );
};

const UpvoteButton = ({ entryId, isVotedByUser, upvotes, isLoggedIn }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleVote = async () => {
        if (!isLoggedIn) {
            toast.error('You must be logged in to upvote.');
            return;
        }
        setLoading(true);
        await dispatch(voteOnLeaderboardEntry(entryId, isVotedByUser));
        setLoading(false);
    };

    return (
        <button
            onClick={handleVote}
            className={`flex flex-row items-center px-3 py-1 rounded-lg shadow-md ${
                isVotedByUser ? 'bg-gray-700' : 'bg-black'
            } hover:shadow-lg transition-shadow duration-200 h-fit relative text-white`}
            disabled={loading}
        >
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="loader"></div>
                </div>
            )}
            <FaArrowUp className="hover:text-yellow-500 transition-colors duration-200 mr-1" />
            {upvotes || 0}
        </button>
    );
};

const CommentButton = ({ leaderboardEntryId, commentCount, comments }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const onClick = () => {
        if (comments) return;
        dispatch(fetchLeaderboardEntryStart());
        setTimeout(() => {
            setSearchParams({
                ...Object.fromEntries(searchParams),
                leaderboardEntryId: leaderboardEntryId
            });
        }, 100);
    };

    return (
        <button
            onClick={onClick} // Set onClick event for the button
            className="flex flex-row items-center px-3 py-1 rounded-lg shadow-md bg-black hover:shadow-lg transition-shadow duration-200 h-fit hover:bg-gray-700 text-white"
        >
            <FaComment className="text-green-500 mr-1 hover:text-green-700 transition-colors duration-200" />
            {commentCount || 0}
        </button>
    );
};

const RemoveButton = ({ projectId }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleRemove = async () => {
        setLoading(true);
        await dispatch(removeProjectFromLeaderboard(projectId));
        setLoading(false);
    };

    return (
        <button
            onClick={handleRemove}
            className="flex flex-row items-center px-3 py-1 rounded-lg shadow-md bg-red-600 hover:shadow-lg transition-shadow duration-200 h-fit hover:bg-red-700 text-white relative"
            disabled={loading}
        >
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-red-600 bg-opacity-75">
                    <div className="loader"></div>
                </div>
            )}
            <FaTrash className="mr-1" />
            Remove
        </button>
    );
};

const CommentInput = ({ entryId, isLoggedIn }) => {
    const [comment, setComment] = useState('');
    const [showSubmit, setShowSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleComment = async e => {
        e.preventDefault();
        if (!isLoggedIn) {
            toast.error('You must be logged in to comment.');
            return;
        }
        if (comment.trim()) {
            setLoading(true);
            await dispatch(commentOnLeaderboardEntry(entryId, null, comment));
            setComment('');
            setShowSubmit(false);
            setLoading(false);
        }
    };

    const handleCommentChange = e => {
        setComment(e.target.value);
        setShowSubmit(e.target.value.trim() !== '');
    };

    const handleFocus = () => {
        setShowSubmit(true);
    };

    const handleBlur = () => {
        if (!comment.trim()) {
            setShowSubmit(false);
        }
    };

    return (
        <form onSubmit={handleComment} className="mt-4 relative">
            <input
                type="text"
                value={comment}
                onChange={handleCommentChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Add a comment..."
                className="w-full px-3 py-2 border rounded bg-black text-white"
                disabled={loading}
            />
            {showSubmit && (
                <button
                    type="submit"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 px-4 py-1 bg-blue-500 text-white rounded"
                    disabled={loading}
                >
                    {loading ? 'Submitting...' : 'Submit'}
                </button>
            )}
        </form>
    );
};
