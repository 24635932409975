import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { useDispatch } from 'react-redux';
import { toggleSubscribeModal } from '../../../../redux/reducers/generic/billing';
import { FaCrown } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProject } from '../../../../context-providers/Project';

export const UpgradeToPremiumButton = () => {
    const { isShare, isSubbed, isHomePage } = useSubscriptionPayment();
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    const handleClick = () => {
        if (isShare) {
            navigate('/auth');
            return;
        }
        dispatch(toggleSubscribeModal(true));
    };

    if (isSubbed) {
        return null;
    }

    let showUpgrade = isHomePage;

    return (
        showUpgrade && (
            <button
                onClick={handleClick}
                className="my-2 group relative px-6 py-3 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full shadow-lg hover:from-blue-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300 ease-in-out transform hover:scale-105"
            >
                <span className="flex items-center justify-center">
                    <FaCrown className="mr-2 text-yellow-300" />
                    <span>Upgrade {!isMobile ? ' for Full Access' : ''}</span>
                </span>
            </button>
        )
    );
};
