import { REMOVE_PROJECT_FROM_LEADERBOARD } from '../../../api/graphQL/solo/mutator';
import { client } from '../../../api/client';
import { setLeaderboardProjectEntry } from '../../reducers/leaderboard';

export const removeProjectFromLeaderboard =
    (projectId: string) => async (dispatch: any) => {
        try {
            const { data } = await client.mutate({
                mutation: REMOVE_PROJECT_FROM_LEADERBOARD,
                variables: {
                    projectId
                },
                fetchPolicy: 'network-only'
            });

            console.log({ data });

            dispatch(setLeaderboardProjectEntry(null));

            return 'Successfully removed project from leaderboard.';
        } catch (error) {
            console.log({ error });
            return error.message;
        }
    };
