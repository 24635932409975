import { useSelector } from 'react-redux';
import { selectSow } from '../../../redux/reducers/generic/sow';
import { Link, useLocation } from 'react-router-dom';
import { RoadmapChart } from './RoadmapChart';
import { Spinner } from '../../Components/Spinner';
import SkillLevel from '../Docs/EditorHeader/skill_level';
import { JiraSync } from './JiraSync';
import { LinearSync } from './LinearSync';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { toggleBillingModalOpen } from '../../../redux/reducers/generic/billing';
import { useDispatch } from 'react-redux';
import { useProject } from '../../../context-providers/Project';

export const Roadmap = () => {
    let sow = useSelector(selectSow);
    const { pathname } = useLocation();
    let isLoadingSow = sow?.projectId ? !sow.completed : true;
    const { projectId } = useProject();

    const { tier, isShare, isSubbed, isHomePage, isEnterprise, isSolo } =
        useSubscriptionPayment();
    const showUpgrade = tier === 'SoloPlus' || !isSubbed;

    return (
        <div className="mt-4 min-w-[350px] min-h-[300px] flex flex-col gap-y-4">
            {!sow?.projectId && <LoadingSoW />}
            {!pathname.includes('share') && (
                <div
                    className={`flex flex-col gap-x-2 gap-y-2 justify-end items-center`}
                >
                    {isShare && <UpgradeFromShare />}
                    {showUpgrade && (isSolo || isEnterprise) && (
                        <UpgradeToPremium
                            text={isSolo ? 'Solo' : 'Enterprise'}
                        />
                    )}

                    {!isLoadingSow &&
                        isSubbed &&
                        (isEnterprise || tier === 'SoloPremium') && (
                            <>
                                <JiraSync projectId={projectId} />
                                <LinearSync projectId={projectId} />
                            </>
                        )}
                </div>
            )}

            {isSubbed && (
                <div className="mt-3 mb-4 mx-8 flex items-center justify-center">
                    <SkillLevel />
                </div>
            )}

            <RoadmapChart sow={sow} type={sow?.type} projectId={projectId} />
        </div>
    );
};
const UpgradeToPremium = ({ text }) => {
    const dispatch = useDispatch();

    const handleUpgrade = () => {
        dispatch(toggleBillingModalOpen(true));
    };

    return (
        <div className="w-full flex items-center justify-center">
            <button
                onClick={handleUpgrade}
                className="flex flex-row items-center gap-4 bg-green-600 hover:bg-green-500 text-white rounded-lg px-4 py-3 text-sm"
            >
                <img src="/atlassian.svg" alt="Premium icon" className="h-6" />
                <img src="/linear.svg" alt="Premium icon" className="h-6" />
                <div className="text-left">
                    <div>
                        Upgrade to <b>{text} Premium</b>
                    </div>
                    <div>to Sync with Jira / Linear</div>
                </div>
            </button>
        </div>
    );
};

const UpgradeFromShare = () => {
    return (
        <div className="w-full flex items-center justify-center">
            <Link
                to="/auth"
                className="flex flex-row items-center gap-4 bg-green-600 hover:bg-green-500 text-white rounded-lg px-4 py-3 text-sm"
            >
                <img src="/atlassian.svg" alt="Premium icon" className="h-6" />
                <img src="/linear.svg" alt="Premium icon" className="h-6" />
                <div className="text-left">
                    <div>Create a pre.dev account</div>
                    <div>to Sync with Jira / Linear</div>
                </div>
            </Link>
        </div>
    );
};

const LoadingSoW = () => {
    return (
        <div className="w-full flex items-center justify-center">
            <div className="flex flex-row items-center justify-center gap-x-2 border border-gray-400 rounded-lg p-2">
                <Spinner h="5" w="5" />
                <p className="text-black text-center text-md">
                    Loading Roadmap...
                </p>
            </div>
        </div>
    );
};
