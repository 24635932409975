import React, { createContext, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getProjectsByUser } from '../redux/actions/solo/getProjectsByUser';
import {
    selectCreationMethodFilter,
    selectProjects,
    selectProjectsSkip,
    selectProjectsTotal,
    selectSearchQuery,
    selectSort
} from '../redux/reducers/generic/projects';
import { useParams } from 'react-router-dom';
import { getProjectsByEnterpriseOrganization } from '../redux/actions/enterprise/getProjectsByEnterpriseOrganization';
import { projectsLimit } from '../constants';
import { useState } from 'react';
import { setEnterpriseId } from '../redux/reducers/enterprise/enterpriseOrganization';

const ProjectsContext = createContext();

export function ProjectsProvider({ children, isAdmin }) {
    const { doesSessionExist } = useSessionContext();
    const projects = useSelector(selectProjects);
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const skip = useSelector(selectProjectsSkip);
    const totalProjects = useSelector(selectProjectsTotal);
    const searchQuery = useSelector(selectSearchQuery);
    const sort = useSelector(selectSort);
    const creationMethodFilter = useSelector(selectCreationMethodFilter);

    const [prevSearchQuery, setPrevSearchQuery] = useState(searchQuery);
    const [prevCreationMethodFilter, setPrevCreationMethodFilter] =
        useState(creationMethodFilter);
    const [prevSort, setPrevSort] = useState(sort);

    let shouldFetchMore =
        totalProjects > projects.length ||
        projects.length === 0 ||
        prevSearchQuery !== searchQuery ||
        creationMethodFilter !== prevCreationMethodFilter ||
        JSON.stringify(sort) !== JSON.stringify(prevSort);

    useEffect(() => {
        if (prevSearchQuery !== searchQuery) {
            setPrevSearchQuery(searchQuery);
        }
    }, [searchQuery, prevSearchQuery]);

    useEffect(() => {
        if (prevCreationMethodFilter !== creationMethodFilter) {
            setPrevCreationMethodFilter(creationMethodFilter);
        }
    }, [creationMethodFilter, prevCreationMethodFilter]);

    useEffect(() => {
        if (prevSort !== sort) {
            setPrevSort(sort);
        }
    }, [sort, prevSort]);

    useEffect(() => {
        dispatch(setEnterpriseId(enterpriseOrganizationId));
    }, [enterpriseOrganizationId]);

    //determine when to mount this data
    useEffect(() => {
        if (doesSessionExist && shouldFetchMore) {
            if (enterpriseOrganizationId) {
                dispatch(
                    getProjectsByEnterpriseOrganization({
                        enterpriseOrganizationId,
                        skip,
                        limit: projectsLimit,
                        searchQuery,
                        creationMethodFilter,
                        sort
                    })
                );
            } else {
                dispatch(
                    getProjectsByUser({
                        skip,
                        limit: projectsLimit,
                        searchQuery
                    })
                );
            }
        }
    }, [
        doesSessionExist,
        enterpriseOrganizationId,
        skip,
        searchQuery,
        creationMethodFilter,
        sort
    ]);

    return (
        <ProjectsContext.Provider
            value={{ projects: !isAdmin ? projects : [], isAdmin }}
        >
            {children}
        </ProjectsContext.Provider>
    );
}

export const useProjects = () => useContext(ProjectsContext);
