import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useProject } from '../../../../../context-providers/Project';
import { getProjectDescription } from '../../../../../redux/actions/leaderboard/get_project_description';
import { v4 as uuidv4 } from 'uuid';
import { FaMagic } from 'react-icons/fa'; // Importing the wand icon
import { Spinner } from '../../../../Components/Spinner';
import { useSubscription } from '@apollo/client';
import { LEADERBOARD_PROJECT_DESCRIPTION_STREAM } from '../../../../../api/graphQL/solo/subscription';
import { submitProjectToLeaderboard } from '../../../../../redux/actions/leaderboard/submit_project_to_leaderboard';
import { toast } from 'sonner';

const LeaderInputSection = () => {
    const { projectId, project } = useProject();
    const [description, setDescription] = useState('');
    const [name, setName] = useState(project.name);

    return (
        <div className="leader-input-section p-4">
            <ProjectNameInput name={name} setName={setName} />
            <DescriptionTextarea
                description={description}
                setDescription={setDescription}
            />
            <hr className="my-4" />
            <div className="flex space-x-4 w-full justify-center">
                <SubmitButton
                    projectId={projectId}
                    description={description}
                    name={name}
                />
            </div>
        </div>
    );
};

const ProjectNameInput = ({ name, setName }) => (
    <div className="mb-4">
        <label className="block text-white mb-2" htmlFor="project-name">
            Project Name
        </label>
        <input
            id="project-name"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Enter project name"
            className="w-full p-2 border rounded text-white bg-gray-800"
        />
    </div>
);
const DescriptionTextarea = ({ description, setDescription }) => {
    const { projectId } = useProject();
    const dispatch = useDispatch();
    const [randId, setRandId] = useState(null);
    const [loading, setLoading] = useState(false);

    useSubscription(LEADERBOARD_PROJECT_DESCRIPTION_STREAM, {
        variables: { projectId, randId },
        shouldResubscribe: true,
        onData: data => {
            try {
                setDescription(
                    data.data.data.leaderboardProjectDescriptionStream
                        .suggestion
                );
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });

    const handleAnswerForMe = async () => {
        setLoading(true);
        let rand = uuidv4();
        setRandId(rand);
        const projectDescription = await dispatch(
            getProjectDescription({
                projectId,
                randId: rand
            })
        );
        setDescription(projectDescription);
        setLoading(false);
    };

    return (
        <div className="flex flex-col gap-y-2">
            <div className="flex justify-between items-center">
                <label
                    className="block text-white"
                    htmlFor="leaderboard-submission"
                >
                    Project Description
                </label>
            </div>
            <textarea
                id="leaderboard-submission"
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Enter the project description"
                className="w-full p-2 border rounded text-white bg-gray-800 mb-2 min-h-[150px]"
            />
            <button
                onClick={handleAnswerForMe}
                className={`w-fit px-4 py-2 flex gap-x-2 items-center bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold rounded-full shadow-lg transition-transform transform duration-300 ${
                    loading
                        ? 'cursor-not-allowed opacity-50'
                        : 'hover:from-green-600 hover:to-teal-600 hover:scale-105'
                }`}
                disabled={loading}
            >
                {loading ? <Spinner w="3" /> : <FaMagic />}
                {loading ? 'Generating Description...' : 'Generate Description'}
            </button>
        </div>
    );
};
const SubmitButton = ({ projectId, description, name }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const submitIdea = async () => {
        setLoading(true);
        await dispatch(
            submitProjectToLeaderboard({
                projectId,
                description,
                name
            })
        );
        setLoading(false);
    };

    return (
        <button
            onClick={submitIdea}
            className={`px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-full shadow-lg transition-transform transform duration-300 ${
                loading
                    ? 'cursor-not-allowed opacity-50'
                    : 'hover:from-blue-600 hover:to-purple-600 hover:scale-105'
            }`}
            disabled={loading}
        >
            {loading ? <Spinner w="3" /> : 'Submit Idea'}
        </button>
    );
};
export default LeaderInputSection;
