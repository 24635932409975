import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    clearStateProject,
    selectProjectLoading
} from '../../redux/reducers/generic/project';
import { Chat } from './Chat';
import { ProjectArchitecture } from './ArchitectureGraph';
import RequestModal from './RequestMeeting/Requestmodal';
import { useEffect, useState } from 'react';
import { useProject } from '../../context-providers/Project';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import {
    clearStateProjectArchitecture,
    setPanelResized
} from '../../redux/reducers/generic/projectArchitecture';
import { Loading } from '../Components/Loading';
import { FullScreenButton } from './Chat/FullScreenButton';
import { UpdatingArchitectureFloater } from './UpdatingArchitectureFloater';
import { DemoBanner } from './DemoBanner';
import { DockBar } from './DockBar';
import { Docs } from './Docs';
import { Roadmap } from './Roadmap';
import { CodeGen } from './CodeGen';
import { getSow } from '../../redux/actions/solo/getSow';
import { setAPIKey } from '../../redux/reducers/enterprise_widget/enterpriseWidget';
import { useSubscriptionPayment } from '../../context-providers/SubscriptionPayment';
import LockDock from './lockDock';
import { Header } from '../WidgetPages/Header';

export const ProjectDashboard = () => {
    const dispatch = useDispatch();
    let { apiKey } = useParams();
    const { isShare, isHomePage } = useSubscriptionPayment();

    if (isShare) {
        dispatch(setAPIKey(apiKey));
    }

    // we can use this to show loading
    const projectLoading = useSelector(selectProjectLoading);
    const {
        project,
        pageType,
        graphType,
        allSearchParams,
        setSearchParams,
        projectId,
        projectArchitecture
    } = useProject();
    const [fullScreen, setFullScreen] = useState(false);

    useEffect(() => {
        let validGraphTypes = projectArchitecture.graphs.map(g => g.side);

        if (!pageType) {
            setSearchParams(
                {
                    ...allSearchParams,
                    pageType: 'chat'
                },
                { replace: true }
            );
        }
        if (
            !graphType ||
            graphType === 'undefined' ||
            !validGraphTypes.includes(graphType)
        ) {
            let newGraphType = validGraphTypes[0];
            if (pageType === 'code') {
                newGraphType = 'frontend';
            }
            console.log('newGraphType', newGraphType);
            console.log(graphType);
            setSearchParams(
                {
                    ...allSearchParams,
                    graphType: newGraphType
                },
                { replace: true }
            );
        }
    }, [pageType, graphType, projectArchitecture]);

    const [isMobile2, setIsMobile] = useState(window.innerWidth < 768);

    const handleResize = () => {
        console.log('resize');
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(getSow(projectId, 'mvp'));
    }, [project]);

    useEffect(() => {
        return () => {
            dispatch(clearStateProjectArchitecture());
        };
    }, []);

    const [timeout, settimeout] = useState(null);

    const onLayout = sizes => {
        document.cookie = `react-resizable-panels:layout=${JSON.stringify(
            sizes
        )}`;
        if (timeout) {
            clearTimeout(timeout);
        }
        let t = setTimeout(() => {
            dispatch(setPanelResized());
        }, 100);
        settimeout(t);
    };
    let [showDemoBanner, setShowDemoBanner] = useState(false);
    let paramToName = {
        sow: 'Documentation',
        roadmap: 'Roadmap',
        code: 'Code Gen (Beta)',
        techstack: 'Tech Stack'
    };

    let lockDock = isHomePage || isShare;

    return projectLoading ? (
        <Loading text="Project" />
    ) : project ? (
        <div className={`bg-black w-full  h-full flex flex-col`}>
            {isShare && !isHomePage && <Header showLogin={true} />}

            <PanelGroup
                direction="horizontal"
                autoSaveId="project-dashboard"
                onLayout={onLayout}
            >
                <Panel defaultSize={40} minSize={35} maxSize={80}>
                    <section
                        className={`px-4 ${
                            !(pageType == 'graph' && isMobile2) && 'h-full'
                        } flex-grow flex flex-col ${
                            pageType == 'chat' && 'justify-between'
                        }`}
                    >
                        <div className="z-2 pb-2 relative flex w-full flex-col items-center justify-center rounded-lg md:shadow-xl">
                            <DemoBanner
                                showDemoBanner={showDemoBanner}
                                setShowDemoBanner={setShowDemoBanner}
                            />
                            <DockBar setFullScreen={setFullScreen} />
                            <UpdatingArchitectureFloater />
                        </div>

                        {pageType == 'code' && (
                            <div
                                className={`dark overflow-y-scroll md:mx-8 px-4 rounded-lg mt-4`}
                            >
                                <CodeGen />
                            </div>
                        )}

                        {(project.internal_version !== 2 ||
                            pageType === 'sow') && (
                            <div
                                className={`bg-slate-50 overflow-y-scroll rounded-lg xl:max-w-4xl max-w-full mx-auto mb-4 ${
                                    pageType !== 'sow' ? 'hidden' : ''
                                }`}
                            >
                                <Docs />
                            </div>
                        )}

                        <div
                            className={`bg-slate-50 overflow-y-scroll rounded-lg md:px-4 xl:max-w-4xl max-w-full mx-auto mb-4 ${
                                pageType !== 'roadmap' ? 'hidden' : ''
                            }`}
                        >
                            <Roadmap />
                        </div>

                        {pageType == 'chat' && (
                            <Chat setShowDemoBanner={setShowDemoBanner} />
                        )}
                    </section>

                    <div className="block md:hidden w-full overflow-auto h-[calc(100vh-120px)]">
                        {pageType == 'graph' && isMobile2 && (
                            <ProjectArchitecture />
                        )}
                    </div>

                    {pageType == 'chat' && (
                        <FullScreenButton
                            fullScreen={fullScreen}
                            setFullScreen={setFullScreen}
                        />
                    )}
                </Panel>

                {!fullScreen && !isMobile2 && pageType === 'chat' && (
                    <>
                        <PanelResizeHandle className="w-4 bg-gray-900 opacity-50">
                            <div className="fixed top-1/2 h-6 w- z-10 text-slate-200 hover:text-gray-300">
                                ↔️
                            </div>
                        </PanelResizeHandle>
                        <Panel minSize={20}>
                            <div className="hidden md:block w-full h-full overflow-auto">
                                <ProjectArchitecture />
                            </div>
                        </Panel>
                    </>
                )}
            </PanelGroup>
            <RequestModal />
        </div>
    ) : null;
};
