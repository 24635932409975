import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { voteOnComment } from '../../redux/actions/leaderboard/vote_on_comment';
import { commentOnLeaderboardEntry } from '../../redux/actions/leaderboard/comment_on_leaderboard_entry';
import { deleteComment } from '../../redux/actions/leaderboard/delete_comment';
import { FaArrowUp, FaComment, FaTrash } from 'react-icons/fa';
import moment from 'moment';
import { toast } from 'sonner';

export const Comment = ({ comment, depth, isLoggedIn }) => {
    return (
        <div className="p-4 border border-gray-700 rounded-lg shadow-md bg-black text-white flex flex-col w-full">
            <div className="flex flex-row gap-x-4 items-center w-full">
                <div>
                    <p
                        className={
                            comment.isDeleted
                                ? 'text-gray-500'
                                : 'text-gray-300'
                        }
                    >
                        {comment.content}
                    </p>
                    <p className="text-gray-400 text-sm mt-1">
                        {moment(comment.createdAt).fromNow()}
                    </p>
                    <div className="flex flex-row gap-x-4 items-center mt-2 justify-between w-full">
                        <div className="flex flex-row gap-x-2 items-center">
                            {!comment.isDeleted && (
                                <UpvoteButton
                                    commentId={comment._id}
                                    isVotedByUser={comment.isVotedByUser}
                                    upvotes={comment.upvotes}
                                    isLoggedIn={isLoggedIn}
                                />
                            )}
                        </div>
                        {comment.isMyComment && !comment.isDeleted && (
                            <RemoveButton commentId={comment._id} />
                        )}
                    </div>
                </div>
            </div>

            {!comment.isDeleted && depth < 6 && (
                <ReplyInput
                    leaderboardEntryId={comment.leaderboardEntryId}
                    commentId={comment._id}
                    isLoggedIn={isLoggedIn}
                />
            )}

            {comment.replies && (
                <div className="mt-4 flex flex-col gap-y-4">
                    {comment.replies.map(reply => (
                        <Comment
                            key={reply._id}
                            comment={reply}
                            depth={depth + 1}
                            isLoggedIn={isLoggedIn}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const UpvoteButton = ({ commentId, isVotedByUser, upvotes, isLoggedIn }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleVote = async () => {
        if (!isLoggedIn) {
            toast.error('You must be logged in to upvote.');
            return;
        }
        setLoading(true);
        await dispatch(voteOnComment(commentId, isVotedByUser));
        setLoading(false);
    };

    return (
        <button
            onClick={handleVote}
            className={`flex flex-row items-center px-3 py-1 rounded-lg shadow-md ${
                isVotedByUser ? 'bg-gray-700' : 'bg-black'
            } hover:shadow-lg transition-shadow duration-200 h-fit relative text-white`}
            disabled={loading}
        >
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="loader"></div>
                </div>
            )}
            <FaArrowUp className="hover:text-yellow-500 transition-colors duration-200 mr-1" />
            {upvotes || 0}
        </button>
    );
};

const RemoveButton = ({ commentId }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleRemove = async () => {
        setLoading(true);
        await dispatch(deleteComment(commentId));
        setLoading(false);
    };

    return (
        <button
            onClick={handleRemove}
            className="flex flex-row items-center px-3 py-1 rounded-lg shadow-md bg-red-600 hover:shadow-lg transition-shadow duration-200 h-fit hover:bg-red-700 text-white relative"
            disabled={loading}
        >
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-red-600 bg-opacity-75">
                    <div className="loader"></div>
                </div>
            )}
            <FaTrash className="mr-1" />
            Remove
        </button>
    );
};

const ReplyInput = ({ leaderboardEntryId, commentId, isLoggedIn }) => {
    const [reply, setReply] = useState('');
    const [showSubmit, setShowSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleReply = async e => {
        e.preventDefault();
        if (!isLoggedIn) {
            toast.error('You must be logged in to reply.');
            return;
        }
        if (reply.trim()) {
            setLoading(true);
            await dispatch(
                commentOnLeaderboardEntry(leaderboardEntryId, commentId, reply)
            );
            setReply('');
            setShowSubmit(false);
            setLoading(false);
        }
    };

    const handleReplyChange = e => {
        setReply(e.target.value);
        setShowSubmit(e.target.value.trim() !== '');
    };

    const handleFocus = () => {
        setShowSubmit(true);
    };

    const handleBlur = () => {
        if (!reply.trim()) {
            setShowSubmit(false);
        }
    };

    return (
        <form onSubmit={handleReply} className="mt-4 relative">
            <input
                type="text"
                value={reply}
                onChange={handleReplyChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Add a reply..."
                className="w-full px-3 py-2 border rounded bg-black text-white"
                disabled={loading}
            />
            {showSubmit && (
                <button
                    type="submit"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 px-4 py-1 bg-blue-500 text-white rounded"
                    disabled={loading}
                >
                    {loading ? 'Submitting...' : 'Submit'}
                </button>
            )}
        </form>
    );
};
