import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useCases } from './data';
import { salesCalendlyLink } from '../../constants';
import { DeveloperAgencies } from './DeveloperAgencies';

export function UseCasePage() {
    const { useCaseId } = useParams<{ useCaseId: string }>();
    const useCase = useCases.find(
        uc => uc.name.toLowerCase().replace(/\s+/g, '-') === useCaseId
    );

    if (!useCase) {
        return <div>Use case not found</div>;
    }
    if (useCase.name === 'Developer Agencies') {
        return <DeveloperAgencies />;
    }

    return (
        <div className="bg-gray-900 min-h-screen text-white mt-[56px]">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <h1 className="text-4xl font-bold mb-8">{useCase.name}</h1>
                <div className="bg-gray-800 rounded-lg p-6 mb-8">
                    <p className="text-xl mb-4">{useCase.description}</p>
                    <div className="inline-block bg-indigo-600 rounded-full px-4 py-2 text-sm font-bold">
                        {useCase.tagline}
                    </div>
                </div>
                <h2 className="text-2xl font-bold mb-4">Key Advantages</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                    {useCase.advantages.map((advantage, index) => (
                        <div
                            key={index}
                            className="bg-gray-800 rounded-lg p-4 flex items-center"
                        >
                            <advantage.icon className="h-8 w-8 text-indigo-400 mr-4" />
                            <span className="text-lg">{advantage.text}</span>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center space-x-4">
                    <Link
                        to="/auth"
                        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg text-lg"
                    >
                        Get Started
                    </Link>
                    <a
                        href={salesCalendlyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-gray-600 hover:bg-gray-500 text-white font-bold py-3 px-6 rounded-lg text-lg"
                    >
                        Contact Us
                    </a>
                </div>
            </div>
        </div>
    );
}
