import WordPullUp from '../../../Components/magicui/WordPullUp';
import { Markdown } from '../../../Components/Markdown/other';
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';
import React, { useState, useEffect, useRef } from 'react';
// NodeDetailModal component: Displays the modal with detailed information about a node

const TerminalWithAutoScroll = ({ lines }) => {
    const terminalRef = useRef(null);

    // Scroll to bottom when lines are added
    useEffect(() => {
        if (terminalRef.current) {
            const terminalElement = terminalRef.current;
            terminalElement.scrollTop = terminalElement.scrollHeight;
        }
    }, [lines]);

    return (
        <Terminal
            height={'400px'}
            onInput={terminalInput =>
                console.log(`New terminal input received: '${terminalInput}'`)
            }
            name="Planning file structure..."
        >
            <div ref={terminalRef} style={{ overflowY: 'auto' }}>
                <TerminalOutput>{lines}</TerminalOutput>
                {/* <TerminalOutput >
                <div className="text-green-400">
            {lines}
            </div>
            </TerminalOutput> */}
            </div>
        </Terminal>
    );
};

// onInput={ terminalInput => console.log(`New terminal input received: '${ terminalInput }'`) }
export const PlanningView = ({ uiView }) => {
    return (
        <>
            <TerminalWithAutoScroll lines={uiView ? uiView.currentPlan : ''} />

            {/* <WordPullUp
        className="text-lg mt-[120px] md:mt-[120px] font-medium tracking-[-0.02em] text-white dark:text-white md:text-6xl"
        words={uiView ? uiView.currentPlan : ''}
    /> */}
        </>
    );
};
