import { COMMENT_ON_LEADERBOARD_ENTRY } from '../../../api/graphQL/solo/mutator';
import { client } from '../../../api/client';
import {
    setLeaderboardEntry,
    setLeaderboardProjectEntry
} from '../../reducers/leaderboard';

export const commentOnLeaderboardEntry =
    (
        leaderboardEntryId: string,
        parentCommentId: string | null,
        content: string
    ) =>
    async (dispatch: any) => {
        try {
            const { data } = await client.mutate({
                mutation: COMMENT_ON_LEADERBOARD_ENTRY,
                variables: {
                    leaderboardEntryId,
                    parentCommentId,
                    content
                },
                fetchPolicy: 'network-only'
            });

            dispatch(setLeaderboardEntry(data.commentOnLeaderboardEntry));
            dispatch(
                setLeaderboardProjectEntry(data.commentOnLeaderboardEntry)
            );

            return 'Successfully commented on leaderboard entry.';
        } catch (error) {
            console.error({ error });
            return error.message;
        }
    };
