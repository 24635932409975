import { useState, useEffect, useRef } from 'react';
import EditCountsComponent from './EditCounts';
// Importing components and hooks from sandpack-react and themes
import { motion } from 'framer-motion';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { Tabs } from './Tabs';
import { Export } from './Export';
import { remixTemplate } from './SandpackFiles/remix_setup';

import Modal from '../../../Components/Modal';
import {
    Sandpack,
    SandpackProvider,
    SandpackLayout,
    SandpackCodeEditor,
    SandpackTests,
    SandpackFileExplorer,
    SandpackPreview,
    SandpackConsole,
    useSandpack,
    useSandpackConsole,
    Navigator
} from '@codesandbox/sandpack-react';
import { PlanningView } from './PlanningView';
//import { toast } from 'sonner';
import { UploadWireframe } from './UploadWireframe';
import { Spinner } from '../../../Components/Spinner'; // Spinner component for loading states
import { atomDark } from '@codesandbox/sandpack-themes'; // Theme for Sandpack components
import { autocompletion, completionKeymap } from '@codemirror/autocomplete'; // Autocompletion for code editor
import { ChatInput } from './Chat/ChatInput'; // Chat input component
import { ChatHistory } from './Chat/ChatHistory'; // Chat history component
import {
    BlahIcon,
    DevicePhoneMobileIcon,
    ClockIcon,
    DocumentTextIcon
} from '@heroicons/react/24/outline';

import { useActiveCode } from '@codesandbox/sandpack-react';
// `data:text/javascript,${encodeURIComponent(customTailwindConfigScript)}`
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Toaster, toast } from 'sonner';
import { set } from 'zod';

// Tabs for UI navigation
const tabs = [
    { name: 'Preview', current: true },
    { name: 'Chat', current: false }
];

// Helper function for conditional class names
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const CustomSandpackEditor = ({
    h,
    currentFiles,
    setFiles,
    readOnly = false,
    setIsEditing
}) => {
    // const codeVersion = useSelector(selectSowVersion);
    const { sandpack } = useSandpack();
    const { activeFile, files } = sandpack;
    const { code, updateCode } = useActiveCode();
    useEffect(() => {
        if (code && currentFiles) {
            try {
                if (code !== currentFiles[activeFile].code) {
                    setIsEditing(true);
                }
                const newFiles = currentFiles;
                console.log(newFiles);
                console.log(activeFile);
                newFiles[activeFile].code = code;
                //console.log(newFiles)
                setFiles(newFiles);

                //setIsEditing(true)
                //  setActiveFile(activeFile)
                //  setActiveCode(code)
            } catch (err) {
                console.log(err);
            }
        }
    }, [files, activeFile]);

    return (
        <SandpackCodeEditor
            style={{ height: h }}
            readOnly={readOnly}
            autoReload={true}
        />
    );
};

const CustomReactDiffViewer = ({ codefile }) => {
    const oldValue = codefile ? codefile.lastVersion : '';
    const newValue = codefile ? codefile.code : '';

    return (
        <motion.div
            className="mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="text-sm text-white"> {codefile.path} </div>
            <ReactDiffViewer
                compareMethod={DiffMethod.WORDS}
                oldValue={oldValue ? oldValue : ''}
                newValue={newValue ? newValue : ''}
                splitView={false}
                showDiffOnly={true}
                useDarkTheme={true}
                leftTitle={codefile ? 'v' + (codefile.version - 1) : ''}
                rightTitle={codefile ? 'v' + codefile.version : ''}
            />
        </motion.div>
    );
};

// UIBuilder component
export const UIBuilder = ({
    projectId,
    nodeId,
    activeTab,
    setActiveTab,
    node,
    regenerateUI,
    uiView,
    testingPhase,
    editingPhase,
    loadingUIView,
    gettingUIView,
    filesWithTests,
    files,
    setFiles,
    imageUrl,
    onTestComplete,
    handleSave,
    theme,
    screenshot,
    extraOptions,
    setExtraOptions,
    isEditing,
    setIsEditing
}) => {
    const customTailwindConfigScript = `
  tailwind.config = {
    theme: {
      extend:
        ${theme ? theme.tailwindConfig : '{}'}
    }
  }`;

    const onClickSave = () => {
        setIsEditing(false);
        handleSave();
    };
    const zipAndDownloadFiles = async () => {
        const zip = new JSZip();
        Object.keys(files).forEach(filePath => {
            zip.file(filePath, files[filePath].code);
        });

        zip.generateAsync({ type: 'blob' }).then(content => {
            saveAs(content, 'project_files.zip');
        });
    };

    const downloadWireframe = async () => {
        const imageData = screenshot;
        if (imageData) {
            var a = document.createElement('a');
            a.href = imageData;
            a.download = 'screeshot.png';
            a.click();
        }
    };

    // Main render function
    return (
        <div className="mb-8 overflow-y-scroll">
            <Modal
                open={extraOptions != false}
                onClose={() => {
                    setExtraOptions(false);
                }}
                bgColor={'bg-black'}
                maxWidth={`w-full md:mx-10`}
            >
                <>
                    {extraOptions === 'PlanningView' && (
                        <PlanningView uiView={uiView} />
                    )}
                    {extraOptions === 'FullScreenPreview' && (
                        <SandpackProvider
                            template="react-ts"
                            theme={atomDark}
                            options={{
                                initMode: 'immediate',
                                rtl: true,
                                autoReload: true,
                                autorun: true,
                                externalResources: [
                                    'https://cdn.tailwindcss.com',
                                    'https://rsms.me/inter/inter.css',
                                    `data:text/javascript,${encodeURIComponent(
                                        customTailwindConfigScript
                                    )}`
                                ]
                            }}
                            customSetup={{
                                dependencies: {
                                    autoprefixer: 'latest',
                                    '@heroicons/react': 'latest',
                                    react: 'latest',
                                    'react-router-dom': 'latest',
                                    'react-dom': 'latest',
                                    html2canvas: 'latest',
                                    '@headlessui/react': 'latest',
                                    'react-scroll': 'latest',
                                    classnames: 'latest',
                                    'react-dev-inspector': 'latest'
                                }
                            }}
                            files={files}
                        >
                            <SandpackPreview
                                style={{ height: '98vh' }}
                                className="w-full mt-6"
                            />
                        </SandpackProvider>
                    )}
                    {extraOptions === 'Diff' && (
                        <div className="flex mt-8">
                            <div className="flex flex-col flex-grow">
                                {uiView &&
                                    uiView.files.map((file, index) => {
                                        return (
                                            <CustomReactDiffViewer
                                                codefile={file}
                                                key={index}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                </>
            </Modal>

            <div className="flex mb-2 flex-row gap-x-2 items-center justify-center mx-auto max-w-screen-sm text-center"></div>

            <style>
                {`
              .animate-ellipsis::after {
                content: '...';
                animation: ellipsis 3s infinite;
              }

              @keyframes ellipsis {
                0% {
                  content: '';
                }
                33% {
                  content: '.';
                }
                66% {
                  content: '..';
                }
                100% {
                  content: '...';
                }
              }
            `}
            </style>
            {/* Navigation tabs */}
            {/* <div className="border-b border-gray-200">
              <nav className="flex justify-center" aria-label="Tabs">
                  {tabs.map(tab => (
                      <a
                          key={tab.name}
                          onClick={() => setActiveTab(tab.name)}
                          className={classNames(
                              activeTab === tab.name
                                  ? 'border-blue-500 text-blue-600'
                                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'w-full border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer'
                          )}
                          aria-current={activeTab === tab.name ? 'page' : undefined}
                      >
                          {tab.name}
                      </a>
                  ))}
              </nav>
          </div> */}

            {/* UI Builder Header */}

            {/* Conditional rendering based on testingPhase */}
            {testingPhase ? (
                <>
                    {filesWithTests ? (
                        <div className="max-h-[300px] mb-8">
                            {/* SandpackProvider for testing phase */}
                            <SandpackProvider
                                template="test-ts"
                                theme={atomDark}
                                options={{
                                    rtl: true,
                                    initMode: 'immediate',
                                    showConsole: true,
                                    autoReload: true,
                                    autorun: true,
                                    externalResources: [
                                        'https://cdn.tailwindcss.com',
                                        'https://rsms.me/inter/inter.css'
                                    ]
                                }}
                                customSetup={{
                                    dependencies: {
                                        '@heroicons/react': 'latest',
                                        react: 'latest',
                                        'react-router-dom': 'latest',
                                        'react-dom': 'latest',
                                        html2canvas: 'latest',
                                        '@headlessui/react': 'latest',
                                        '@testing-library/react': 'latest',
                                        '@testing-library/dom': 'latest',
                                        'react-scroll': 'latest',
                                        classnames: 'latest'
                                    }
                                }}
                                files={filesWithTests}
                            >
                                {/* SandpackTests component */}
                                <div className="max-h-[300px] overflow-auto">
                                    <SandpackTests
                                        verbose={false}
                                        onComplete={specs =>
                                            onTestComplete(specs)
                                        }
                                    />
                                </div>
                                {/* <div>
                    <EditCountsComponent />
                  </div> */}
                            </SandpackProvider>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <>
                    {uiView && !files && uiView.currentPlan && (
                        <PlanningView uiView={uiView} />
                    )}

                    {files && (
                        <SandpackProvider
                            template="react-ts"
                            theme={atomDark}
                            options={{
                                initMode: 'immediate',
                                rtl: true,
                                autoReload: true,
                                autorun: true,
                                externalResources: [
                                    'https://cdn.tailwindcss.com',
                                    'https://rsms.me/inter/inter.css',
                                    `data:text/javascript,${encodeURIComponent(
                                        customTailwindConfigScript
                                    )}`
                                ]
                            }}
                            customSetup={{
                                dependencies: {
                                    autoprefixer: 'latest',
                                    '@heroicons/react': 'latest',
                                    react: 'latest',
                                    'react-router-dom': 'latest',
                                    'react-dom': 'latest',
                                    html2canvas: 'latest',
                                    '@headlessui/react': 'latest',
                                    'react-scroll': 'latest',
                                    classnames: 'latest',
                                    'react-dev-inspector': 'latest'
                                }
                            }}
                            files={files}
                        >
                            <div id="screenId">
                                <>
                                    {node?.id === 'core' && <Navigator />}

                                    {!uiView.status ||
                                    uiView.status != 'finished' ? (
                                        <>
                                            <div className="flex items-center">
                                                <span className="text-purple-600 text-lg mr-4">
                                                    editing{' '}
                                                    <span className="text-green-500">
                                                        {uiView?.files?.length}{' '}
                                                        files
                                                    </span>
                                                </span>
                                                <div className="loader"></div>
                                            </div>
                                            <div className="flex mt-8">
                                                <div className="flex flex-col flex-grow">
                                                    {uiView &&
                                                        uiView.files.map(
                                                            (file, index) => {
                                                                return (
                                                                    <CustomReactDiffViewer
                                                                        codefile={
                                                                            file
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div class="flex justify-between">
                                                <div
                                                    class="inline-flex rounded-md shadow-sm h-10"
                                                    role="group"
                                                >
                                                    <button
                                                        onClick={() =>
                                                            setExtraOptions(
                                                                'PlanningView'
                                                            )
                                                        }
                                                        type="button"
                                                        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                                                    >
                                                        <DocumentTextIcon className="w-5 h-5 me-2" />
                                                        Plan
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            setExtraOptions(
                                                                'Diff'
                                                            )
                                                        }
                                                        type="button"
                                                        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                                                    >
                                                        <div className="w-4 h-4 me-2 text-white">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="#fff"
                                                                viewBox="0 0 512 512"
                                                            >
                                                                <path d="M504 255.5c.3 136.6-111.2 248.4-247.8 248.5-59 0-113.2-20.5-155.8-54.9-11.1-8.9-11.9-25.5-1.8-35.6l11.3-11.3c8.6-8.6 22.4-9.6 31.9-2C173.1 425.1 212.8 440 256 440c101.7 0 184-82.3 184-184 0-101.7-82.3-184-184-184-48.8 0-93.1 19-126.1 49.9l50.8 50.8c10.1 10.1 2.9 27.3-11.3 27.3H24c-8.8 0-16-7.2-16-16V38.6c0-14.3 17.2-21.4 27.3-11.3l49.4 49.4C129.2 34.1 189.6 8 256 8c136.8 0 247.7 110.8 248 247.5zm-180.9 78.8l9.8-12.6c8.1-10.5 6.3-25.5-4.2-33.7L288 256.3V152c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v135.7l65.4 50.9c10.5 8.1 25.5 6.3 33.7-4.2z" />
                                                            </svg>
                                                        </div>
                                                        Diff
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            setExtraOptions(
                                                                'FullScreenPreview'
                                                            )
                                                        }
                                                        type="button"
                                                        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="#fff"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            width="24"
                                                        >
                                                            <path
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
                                                        </svg>
                                                    </button>
                                                </div>

                                                <div className="flex">
                                                    {isEditing && (
                                                        <div className="-mt-1 inline-flex space-x-2 mr-4 ml-4">
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    setIsEditing(
                                                                        false
                                                                    )
                                                                }
                                                                className="h-12 rounded-md bg-red-500/10 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                                            >
                                                                <div className="flex items-center">
                                                                    Cancel
                                                                </div>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={
                                                                    onClickSave
                                                                }
                                                                className="h-12 rounded-md bg-white/10 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                                            >
                                                                <div className="flex items-center">
                                                                    <span className="mr-2">
                                                                        Save
                                                                    </span>
                                                                    <svg
                                                                        className="inline ml-2"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        class="lucide lucide-save"
                                                                    >
                                                                        <path d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" />
                                                                        <path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" />
                                                                        <path d="M7 3v4a1 1 0 0 0 1 1h7" />
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    )}

                                                    <Export
                                                        onClickCode={
                                                            zipAndDownloadFiles
                                                        }
                                                        onClickWireframe={
                                                            downloadWireframe
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            {activeTab === 'Code' ? (
                                                <SandpackLayout
                                                    style={{
                                                        height:
                                                            window.innerHeight -
                                                            300
                                                    }}
                                                    className="w-full"
                                                >
                                                    <SandpackFileExplorer
                                                        style={{
                                                            height: '100%'
                                                        }}
                                                        autoHiddenFiles={false}
                                                    />
                                                    <CustomSandpackEditor
                                                        h={'100%'}
                                                        currentFiles={files}
                                                        setFiles={setFiles}
                                                        setIsEditing={
                                                            setIsEditing
                                                        }
                                                    />
                                                </SandpackLayout>
                                            ) : (
                                                <SandpackPreview
                                                    style={{
                                                        height:
                                                            window.innerHeight -
                                                            300
                                                    }}
                                                    className=" w-full"
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            </div>

                            {/* <div className="md:mx-20">
             <ChatInput
              
                isSolo={isSolo} 
                image_url={imageUrl}
                />
         </div> */}
                        </SandpackProvider>
                    )}
                </>
            )}
        </div>
    );
};

const DiffView = ({ uiView, files, setFiles }) => {
    return (
        <>
            <div className="flex mt-8">
                <SandpackFileExplorer
                    className="w-1/4"
                    autoHiddenFiles={false}
                />
                {files.map((file, index) => {
                    if (file.path == uiView.files[0].path) {
                        return (
                            <CustomReactDiffViewer
                                activeFile={file.path}
                                key={index}
                            />
                        );
                    }
                })}
            </div>
        </>
    );
};
