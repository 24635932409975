import gql from 'graphql-tag';
import { message, project } from '../helper';

export const CREATE_ENTERPRISE_PROJECT = gql`
	mutation createEnterpriseProject(
		$name: String!
		$type: String!
		$userEmail: String
		$apiKey: String!
		$hasExistingContext: Boolean
		$fileKeyS3: String
		$isWidget: Boolean
	) {
		createEnterpriseProject(
			name: $name
			type: $type
			userEmail: $userEmail
			hasExistingContext: $hasExistingContext
			apiKey: $apiKey
			fileKeyS3: $fileKeyS3
			isWidget: $isWidget
		) {
			${project}
		}
	}
`;

export const INITIATE_ENTERPRISE_FOLLOW_UP_QUESTION = gql`
	mutation initiateEnterpriseFollowUpQuestionStream(
		$apiKey: String
		$answer: MessageInput!
		$projectId: ID
		$isWidget: Boolean
	) {
		initiateEnterpriseFollowUpQuestionStream(
			answer: $answer
			projectId: $projectId
			apiKey: $apiKey
			isWidget: $isWidget
		) {
			${message}
		}
	}
`;

export const SUBMIT_HERO_EXPERIENCE = gql`
    mutation submitHeroExperience(
        $answers: [MessageInput]!
        $userEmail: String
    ) {
        submitHeroExperience(answers: $answers, userEmail: $userEmail)
    }
`;

export const REQUEST_ENTERPRISE_MEETING = gql`
    mutation requestEnterpriseMeeting($apiKey: String!, $projectId: String!) {
        requestEnterpriseMeeting(apiKey: $apiKey, projectId: $projectId) {
            _id
            name
            created
            meetingRequested
        }
    }
`;

export const INITIATE_HERO_RANDOM_EXPERIENCE_STREAM = gql`
    mutation initiateHeroRandomExperienceStream(
        $randId: String!
        $inspiration: String
        $answers: [String!]!
    ) {
        initiateHeroRandomExperienceStream(
            randId: $randId
            answers: $answers
            inspiration: $inspiration
        ) {
            suggestion
            completed
            randId
        }
    }
`;
