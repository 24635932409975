import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PricingSection from '../../Pricing/pricing-section';
import { Progress } from '../../ProjectDashboard/ArchitectureGraph/Progress';
import { ScrollIndicator } from './ScrollIndicator';
import { QuestionExperience } from './question_experience';
import { ProjectDashboard } from '../../ProjectDashboard';

export const HeroExperience = () => {
    const [searchParams] = useSearchParams();
    const pricing = searchParams ? searchParams.get('pricing') == 'true' : null;
    const [questionIndex, setQuestionIndex] = useState(0);
    const { projectId } = useParams();

    return (
        <>
            {!projectId && (
                <div className="pt-16 absolute top-0 left-0">
                    <Progress chatLength={questionIndex} total={2} />
                </div>
            )}
            <div className="hero-section pb-10">
                <main className="max-w-screen-xl min-w-0 flex-auto lg:static lg:max-h-full lg:overflow-visible">
                    <ScrollIndicator />
                    <section
                        className="mb-8 pb-16 mx-auto flex sm:pb-10 lg:pb-24"
                        style={{ marginTop: '57px' }}
                    >
                        {!projectId ? (
                            <section className="pb-16 mx-auto flex max-w-8xl flex-col overflow-hidden lg:px-20 lg:pb-24">
                                <div className="px-8 pt-20 mb-20">
                                    <QuestionExperience
                                        questionIndex={questionIndex}
                                        setQuestionIndex={setQuestionIndex}
                                    />
                                </div>
                            </section>
                        ) : (
                            <div
                                className="w-full flex flex-col "
                                style={{ height: 'calc(100vh - 128px)' }}
                            >
                                {!pricing ? (
                                    <ProjectDashboard />
                                ) : (
                                    <PricingSection />
                                )}
                            </div>
                        )}
                    </section>
                </main>
            </div>
        </>
    );
};
