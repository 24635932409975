import React, { createContext, useContext, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import {
    GRAPH_SUBSCRIPTION,
    RECOMMENDED_TECH_STACK_STREAM
} from '../api/graphQL';
import {
    setGraph,
    setGraphVersion
} from '../redux/reducers/generic/projectArchitecture';
import {
    addToGraphVersions,
    addToJobRolesVersions,
    addToTechStackVersions,
    setJobRolesVersion,
    setRecommendedJobRoles,
    setRecommendedTechStack,
    setTechStackVersion
} from '../redux/reducers/generic/project';
import { useProject } from './Project';
import { RECOMMENDED_JOB_ROLES_STREAM } from '../api/graphQL/solo/subscription';
import { toast } from 'sonner';

const GraphContext = createContext();

export const GraphProvider = ({ children }) => {
    const dispatch = useDispatch();
    let { projectId } = useProject();

    useSubscription(RECOMMENDED_TECH_STACK_STREAM, {
        variables: { projectId: projectId || '' },
        shouldResubscribe: true,
        onData: data => {
            try {
                const recommended_teck_stack =
                    data.data.data.recommendedTechStackStream;

                dispatch(
                    addToTechStackVersions(recommended_teck_stack.version)
                );
                dispatch(setTechStackVersion(recommended_teck_stack.version));
                dispatch(setRecommendedTechStack(recommended_teck_stack.stack));
            } catch (error) {
                console.log(error, data);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });

    useSubscription(RECOMMENDED_JOB_ROLES_STREAM, {
        variables: { projectId: projectId || '' },
        shouldResubscribe: true,
        onData: data => {
            try {
                const recommended_job_roles =
                    data.data.data.recommendedJobRolesStream;

                dispatch(addToJobRolesVersions(recommended_job_roles.version));
                dispatch(setJobRolesVersion(recommended_job_roles.version));
                dispatch(setRecommendedJobRoles(recommended_job_roles.roles));
            } catch (error) {
                console.log(error, data);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });

    useSubscription(GRAPH_SUBSCRIPTION, {
        variables: { projectId: projectId || '' },
        shouldResubscribe: true,
        onData: data => {
            try {
                const graph = data.data.data.graphStream;
                if (graph.projectId != projectId) return;
                dispatch(addToGraphVersions(graph.version));
                dispatch(setGraphVersion(graph.version));
                dispatch(setGraph(graph));
                console.log(graph);
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });

    return (
        <GraphContext.Provider value={true}>{children}</GraphContext.Provider>
    );
};

export const useGraph = () => useContext(GraphContext);
