import Modal from '../../../../Components/Modal';
import LeaderInputSection from './LeaderInputSection';
import { FaTrophy } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaderboardEntryForProject } from '../../../../../redux/actions/leaderboard/get_leaderboard_entry_for_project';
import { selectProjectLeaderboardEntry } from '../../../../../redux/reducers/leaderboard';
import { useProject } from '../../../../../context-providers/Project';
import { LeaderboardEntry } from '../../../../Leaderboard/LeaderboardEntry';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

export const LeaderboardInput = ({ showText }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { projectId } = useProject();
    const dispatch = useDispatch();
    const leaderboardEntry = useSelector(selectProjectLeaderboardEntry);
    const { doesSessionExist } = useSessionContext();
    const navigate = useNavigate();

    const handleClick = () => {
        if (doesSessionExist) {
            setIsModalOpen(true);
        } else {
            toast.error('Please login to submit to the leaderboard');
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        console.log('getting leaderboard entry', projectId);
        if (projectId && doesSessionExist) {
            dispatch(getLeaderboardEntryForProject(projectId));
        }
    }, [projectId, doesSessionExist]);

    return (
        <>
            <button
                onClick={handleClick}
                className="flex flex-row px-4 py-2 gap-x-2 justify-center items-center text-blue-400 border border-blue-500 rounded-full hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-colors duration-200"
            >
                <FaTrophy className="h-4" />
            </button>
            {doesSessionExist && (
                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    maxWidth="max-w-2xl"
                    bgColor="bg-black dark"
                >
                    <Header />
                    {!leaderboardEntry && <LeaderInputSection />}
                    {leaderboardEntry && (
                        <LeaderboardEntry entry={leaderboardEntry} />
                    )}
                </Modal>
            )}
        </>
    );
};

const Header = () => (
    <div>
        <h2 className="text-2xl font-bold text-white mb-4">Vet Idea</h2>
        <p className="mb-6 text-white">
            Your project idea will be submitted to the{' '}
            <a href="/leaderboard" className="text-blue-400 underline">
                pre.dev Leaderboard
            </a>{' '}
            where people can vote and comment on it.
        </p>
    </div>
);
